import { Layer } from "@carbon/react";
import { useEffect, useRef, useState } from "react";
import { v4 } from "uuid";
import { arrayToCommaString, formComponents, sanitizeItemInputs, sanitizePhase } from "../../../../../Utils/utils.js";
import Explode from "./Explode/index.js";
import Filter from "./Filter/index.js";
import Outlier from "./Outlier/index.js";
import ReplaceNA from "./ReplaceNA/index.js";
import ReplaceValues from "./ReplaceValues/index.js";
import TypeEnforcement from "./TypeEnforcement/index.js";

const add_modified_indicator_column_types = [
    { id: 'True', text: 'True' },
    { id: 'False', text: 'False' },
]

const Index = ({ item, output, setParentErrorState, parentErrorState, type, reset, setReset }) => {
    const [keyState, setKeyState] = useState(v4())
    const [localState, setLocalState] = useState({ ...sanitizePhase })
    const [inputState, setInputState] = useState([])
    const [inputItemsState, setInputItemsState] = useState([...recalculateInputs()])
    const inputRef = useRef()
    useEffect(() => {
        let localitem = { ...item }
        if (!item || Object.keys(item).length === 0) {
            localitem = { ...sanitizePhase }
        }
        setKeyState(() => v4())
        setLocalState((state) => ({ ...state, ...sanitizeItemInputs(localitem) }))
    }, [item])
    useEffect(() => {
        setLocalState((prevState) => ({ ...prevState, sanitization_type: type }))
    }, [type])
    // useEffect(() => {
    //     output(localState)
    //     setInputItemsState(() => [...recalculateInputs()])
    // }, [localState])
    useEffect(() => {
        setInputItemsState(() => [...recalculateInputs()])
    }, [parentErrorState])
    useEffect(() => {
        if (reset) {
            setLocalState(() => ({ ...sanitizePhase }))
            setReset(() => false)
        }
    }, [reset])
    useEffect(() => {
        output(localState)  //Se envía el localState al padre para detectar errores de validación al pulsar Guardar
        setInputState(() => formComponents(inputItemsState, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [inputItemsState])

    function recalculateInputs() {
        return [
            {
                type: 'text',
                id: 'target_column',
                invalidText: parentErrorState.target_column,
                labelText: 'Columnas que desea procesar',
                placeholder: 'Introduce los nombres de las columnas separadas por comas',
                value: arrayToCommaString(localState.target_column),
                invalid: parentErrorState.target_column,
            },
            {
                type: 'text',
                id: 'output_column',
                invalidText: parentErrorState.output_column,
                labelText: 'Añadir columna de salida, (En blanco para sobrescribir)',
                placeholder: 'Indica columna de salida, si la necesitas añadir',
                value: localState.output_column,
                invalid: parentErrorState.output_column,
            },
            {
                type: 'combo',
                id: 'add_modified_indicator_column',
                invalidText: parentErrorState.add_modified_indicator_column,
                labelText: 'Añadir columna indicadora',
                placeholder: 'Indica si necesitas añadir columna indicadora',
                value: localState.add_modified_indicator_column,
                invalid: parentErrorState.add_modified_indicator_column,
                types: add_modified_indicator_column_types
            },
        ]
    }

    const formTypes = {
        'TYPE ENFORCEMENT': <TypeEnforcement item={item ? item.parameters : ''}
            output={(returnData) => setLocalState((state) => ({
                ...state,
                parameters: { ...state.parameters, ...returnData }
            }))}
            setParentErrorState={setParentErrorState}
            parentErrorState={parentErrorState}
            reset={reset} setReset={setReset}></TypeEnforcement>,
        'REPLACE NA': <ReplaceNA item={item ? item.parameters : ''}
            output={(returnData) => setLocalState((state) => ({
                ...state,
                parameters: { ...state.parameters, ...returnData }
            }))}
            setParentErrorState={setParentErrorState}
            parentErrorState={parentErrorState}
            reset={reset} setReset={setReset}></ReplaceNA>,
        /* 'FILTER': <Filter item={item ? item.parameters : ''}
            output={(returnData) => setLocalState((state) => ({
                ...state,
                parameters: { ...state.parameters, ...returnData }
            }))}
            setParentErrorState={setParentErrorState}
            parentErrorState={parentErrorState}
            reset={reset} setReset={setReset}></Filter>, */
        'REPLACE VALUES': <ReplaceValues item={item ? item.parameters : ''}
            output={(returnData) => setLocalState((state) => ({
                ...state,
                parameters: { ...state.parameters, ...returnData }
            }))}
            setParentErrorState={setParentErrorState}
            parentErrorState={parentErrorState}
            reset={reset} setReset={setReset}></ReplaceValues>,
        'EXPLODE': <Explode item={item ? item.parameters : ''}
            output={(returnData) => setLocalState((state) => ({
                ...state,
                parameters: { ...state.parameters, ...returnData }
            }))}
            setParentErrorState={setParentErrorState}
            parentErrorState={parentErrorState}
            reset={reset} setReset={setReset}></Explode>,
        'OUTLIER': <Outlier item={item ? item.parameters : ''}
            output={(returnData) => setLocalState((state) => ({
                ...state,
                parameters: { ...state.parameters, ...returnData }
            }))}
            setParentErrorState={setParentErrorState}
            parentErrorState={parentErrorState}
            reset={reset} setReset={setReset}></Outlier>,
    }

    function formChooser() {
        return formTypes[localState.sanitization_type]
    }

    return (<Layer>
        <div className="sanitizeForm">
            <div className="inner-content">
                <div className={'form-content'}>
                    {localState.sanitization_type !== '' &&
                        inputState
                    }
                    {localState.sanitization_type !== '' &&
                        formChooser()
                    }
                </div>
            </div>
        </div>
    </Layer>)
}


export default Index
