import React, {useEffect, useRef, useState} from 'react';
import {v4} from "uuid";
import {data_types_select, formComponents, parameters, sanitizeItemInputs} from "../../../../../../../Utils/utils.js";


function Index({item, setParentErrorState, parentErrorState, output, reset, setReset, type}) {
    const [keyState, setKeyState] = useState(v4())
    const inputRef = useRef()
    const [inputState, setInputState] = useState([])
    const [localState, setLocalState] = useState({...parameters})
    const [conditionalInputsState, setConditionalInputsState] = useState([])
    const [conditionalInputitemsState, setConditionalInputItemsState] = useState([])
    const [inputItemsState, setInputItemsState] = useState([...recalculateInputs()])

    function recalculateInputs() {
        return [{
            type: 'select',
            id: 'data_type',
            invalidText: parentErrorState.parameters.data_type,
            labelText: 'Tipo de forzado de las variables',
            placeholder: 'Selecciona el tipo de la variable a la que desea forzar',
            value: localState.data_type,
            invalid: parentErrorState.parameters.data_type,
            types: data_types_select.filter((e) => e.key !== 'CATEGORICAL'),
            parentErrorName: 'parameters'
        }]
    }

    useEffect(() => {
        let localitem = {...item}
        if (!item || Object.keys(item).length === 0) {
            localitem = {...parameters}
            localitem.data_type = 'STRING'
            localitem.replacement_type = type
        }
        setKeyState(() => v4())
        setLocalState((state) => ({...state, ...sanitizeItemInputs(localitem)}))
        enforceChooser()
    }, [item])
    useEffect(() => {
        const localItem = {...localState}
        if (localState.data_type !== 'DATE') {
            localItem.date_format = ''
        }
        output(localItem)
        setInputItemsState(() => [...recalculateInputs()])
    }, [localState])
    useEffect(() => {
        setInputItemsState(() => [...recalculateInputs()])
    }, [parentErrorState])
    useEffect(() => {
        enforceChooser()
        setInputState(() => formComponents(inputItemsState, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [inputItemsState])

    useEffect(() => {
        if (reset) {
            setLocalState(() => ({...parameters}))
            setReset(() => false)
        }
    }, [reset])

    useEffect(() => {
        setConditionalInputsState(() => formComponents(conditionalInputitemsState, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [conditionalInputitemsState])

    function enforceChooser() {
        switch (localState.data_type) {
            case 'DATE':
                setConditionalInputItemsState(() => [{
                    type: 'text',
                    id: 'date_format',
                    invalidText: parentErrorState.parameters.date_format,
                    labelText: 'Formato de fecha',
                    placeholder: 'Introduce el formato de fecha',
                    value: localState.date_format,
                    invalid: parentErrorState.parameters.date_format,
                    parentErrorName: 'parameters'
                }])
                break;
            default:
                setConditionalInputItemsState(() => [])
                break;
        }
    }

    return (
        <div>
            {inputState}
            {conditionalInputsState}
        </div>
    );
}

export default Index;
