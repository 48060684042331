import {Layer} from "@carbon/react";
import {useEffect, useState} from "react";
import Phase from "./Phase/index.js";

const Index = ({phases = [], title, setSelection, changeOrder}) => {
    const [items, setItems] = useState()
    useEffect(() => {
        phaseItems()
    }, [phases])
    const phaseItems = () => {
        let itms
        if (phases.length > 0) {
            phases = phases.sort((a, b) => a.order - b.order)
            itms = phases.map((phase, index) => {
                return <Phase key={`phase#${index}`} phaseSelect={(sel) => dispatchSelection(sel)} phase={phase}
                              index={index}
                              last={phases.length}
                              changeOrder={changeOrder}></Phase>


            })
        } else {
            itms = (<></>)
        }
        setItems(() => itms)
    }
    return (
        <Layer className="">
            <h4>{title}</h4>
            <div className="swim-lane-container">
                <div className={'phases-container'}>
                    {items}
                </div>
            </div>
        </Layer>)

    function dispatchSelection(phase) {
        setSelection(phase)
    }
}

export default Index
