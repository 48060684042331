import {useEffect, useState} from "react";
import {Column, Grid, Loading, Select, SelectItem} from "@carbon/react";
import {useDispatch, useSelector} from "react-redux";
import {dashboardDataRequestThunk, dashboardOptionsRequestThunk} from "../../store/reducers/dashboardReducer";
import {ChartModel} from "../../Models/ChartModel";
import {FaceDizzy, Restart} from "@carbon/icons-react";
import {chartChooser, optionsList, prepareChart} from "../../Utils/chartUtils";
import {useHasChanged} from "../../Utils/utils";
import {pushNotification} from "../../store/reducers/notificationsReducer";
import {NotificationModel} from "../../Models/Notification";
import PanelPlaceholder from "../../components/Panels/PanelPlaceholder";
import ErrorPanel from "../../components/Panels/ErrorPanel";

let loadOnce = false;
const Index = () => {
    const dispatch = useDispatch()
    const dashboardStore = useSelector((state) => state.dashboard) // contiene todos los dashboards en forma de clase
    const [localState, setlocalState] = useState({charts: []})
    const [selectionsState, setSelectionState] = useState({target: {value: -1}})
    const [newGraphsState, setNewGraphsState] = useState([])
    const [loading, setLoading] = useState(false)
    const [loadingGraphs, setLoadingGraphs] = useState(false)
    const [error, setError] = useState('')
    const [panelLoadingStatus, setPanelLoadingStatus] = useState([])
    const hasSelectionChanged = useHasChanged(selectionsState)

    const options = {
        rootMargin: '10px 0px', // like css property
        threshold: 1.0
    }
    let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                let id = selectionsState.target.value
                let graphId = entry.target.id.split('panel_placeholder')[1].split('-')
                const filteredPanel = dashboardStore.panels.find((e) => Number(e.id) === Number(id))
                const graph = filteredPanel.data.find((g) => g.id === Number(graphId[1]) && g.chart_type === graphId[2])
                loadPanel(graph)
                observer.unobserve(entry.target);
            }
        });
    }, options);

    useEffect(() => {
        if (dashboardStore.panels.length > 0) {
            setLoadPanelsbase(dashboardStore.panels[0].id)
            setSelectionState({target: {value: dashboardStore.panels[0].id}})
        } else {
            setLoading(() => true)
            if (!loadOnce) {
                loadOnce = true
                setError(() => '')
                dispatchPanels()
            } else {
                setLoading(() => false)
                setError(() => '')

                if (dashboardStore.panels.length === 0) {
                    setError(() => loadOnce ? 'Dashboard' : '')
                } else {
                    setSelectionState({target: {value: dashboardStore.panels[0].id}})
                }
            }
        }

    }, [dashboardStore.panels])
    useEffect(() => {
        if (selectionsState.target.value > 0 && hasSelectionChanged) {
            resetStates()
            setLoadPanelsbase(selectionsState.target.value)
            setLoadingGraphs(() => true)
            loadDashboard()
        }
    }, [selectionsState])
    useEffect(() => {
        if (newGraphsState.length > 0) {
            prepareData()
            setError(() => '')
        } else {
            if (!loadingGraphs && !loadOnce) {
                setError(() => 'Panel')
            }
        }
    }, [newGraphsState])

    // Crea la interfaz de la página de colmena
    return (<div id="dashboard-page" className="page-container dashboard">
        <div className="dashboard-inner">
            <div className="selection-wrapper">
                <div className='selection-wrapper-inner-margins'>
                    <div className="dashboard-header">
                        <h3>Gráficas disponibles</h3>
                        <Restart onClick={() => reloadPanels()} size={24}
                                 className={dashboardStore.loadingDashboard ? "reload-icon rotate-all" : "reload-icon"}/>
                    </div>
                    {!loading &&
                        <>
                            <div className="selector-inner">
                                {!dashboardStore.loadingDashboard &&
                                    <>
                                        <Select
                                            defaultValue="placeholder-item"
                                            helperText="Los paneles se configuran en administración"
                                            id="select-1"
                                            labelText="Seleccione un panel"
                                            size="lg"
                                            className="dashboard-selector"
                                            onChange={(e) => setSelectionState(e)}
                                            disabled={dashboardStore.panels.length === 0}
                                        >
                                            {dashboardStore.panels !== '' ? dashboardStore.panels.map((panel) => {
                                                return <SelectItem key={panel.id}
                                                                   text={`${panel.name.toUpperCase()} | ${panel.data.length} paneles`}
                                                                   value={panel.id}
                                                />
                                            }) : ''}
                                        </Select>
                                    </>
                                }
                            </div>
                        </>
                    }
                    {loading &&
                        <div className="status-container">
                            <Loading
                                description="Active loading indicator" withOverlay={false}
                            />
                        </div>
                    }</div>
            </div>
            <div className="chart-wrapper">
                <Grid fullWidth={true} className="charts-container">
                    {dashboardStore.panels !== '' && selectionsState.target.value >= 0
                        && dashboardStore.panels[getPanelIndex(selectionsState.target.value)].data.length > 0 &&
                        <>
                            {dashboardStore.panels[getPanelIndex(selectionsState.target.value)].data.map((panel) =>
                                <Column lg={panel.size ? panel.size : '8'} xlg={panel.size ? panel.size : '8'} sm={4} md={8}
                                    key={panel.name + "-" + panel.chart_type + "#" + panel.id}>
                                    <>
                                        {getGraphIndex(panel.id, panel.chart_type) > -1 ?
                                            !localState.charts[getGraphIndex(panel.id, panel.chart_type)].error ?
                                                chartChooser(localState.charts[getGraphIndex(panel.id, panel.chart_type)]) :
                                                <ErrorPanel panel={panel}
                                                            reloadPanel={reloadPanel}
                                                            loading={panelLoadingStatus[getPanelLoadingIndex(panel.id, panel.chart_type)]?.loading}></ErrorPanel> :
                                            <PanelPlaceholder panel={panel}></PanelPlaceholder>
                                        }
                                    </>
                                </Column>)}
                        </>
                    }
                </Grid>
            </div>
            <div className='error-wrapper'>
                {error !== '' &&
                    <div className="no-content-wrapper">
                        <div className="no-content-wrapper-inner">
                            <h3>No tiene ningún {error} configurado</h3>
                            <FaceDizzy size={32}/>
                        </div>
                        <h4>Para configurar, acceda a la pantalla de administración</h4>
                    </div>
                }
            </div>
        </div>
    </div>)

// Carga los paneles y en aquel que coincida el índice establece loading === false
    function setLoadPanelsbase(dashboardId) {
        const panels = dashboardStore.panels[getPanelIndex(dashboardId)].data.map(e => {
            return {...e, loading: false}
        })
        setPanelLoadingStatus(() => panels)
    }

    // Obtiene el índiece de un panel del dashboard
    function getPanelIndex(panelId) {
        return dashboardStore.panels.findIndex(e => e.id === Number(panelId))
    }

    // Obtiene el id y tipo del panel de los graficos que ya han sido cargados
    function getGraphIndex(panelId, chart_type) {
        return localState.charts.findIndex(e => e.options.id === Number(panelId) && e.type === chart_type)
    }

    // Obtiene el panel de los graficos que ya han sido cargados
    function getPanelLoadingIndex(panelId, chart_type) {
        return panelLoadingStatus.findIndex(e => e.id === Number(panelId) && e.chart_type === chart_type)
    }

    // Obtiene el panel de los graficos que son de nueva implementación
    function getNewGraphIndex(panelId, chart_type) {
        return newGraphsState.findIndex(e => e.graph_info.id === Number(panelId) && e.graph_info.chart_type === chart_type)
    }

    function resetStates() {
        setlocalState(() => ({charts: []}))
        setNewGraphsState(() => [])
        setPanelLoadingStatus(() => [])
    }

    // Botón de recargar dashboards de la página
    function reloadPanels() {
        setLoading(() => true)
        resetStates()
        dispatchPanels()
    }

    // Boton de recargar un panel que ha dado fallo
    function reloadPanel(panel) {
        togglePanelLoadingStatus(panel, true)
        loadPanel(panel)
    }


    function togglePanelLoadingStatus(panel, state) {
        // Comprueba si hay la misma cantidad de paneles cargados que los creados
        if (panelLoadingStatus.length === dashboardStore.panels[getPanelIndex(selectionsState.target.value)].data.length) {
            const newState = panelLoadingStatus.map((e) => {
                // A los paneles cargados le pone un estado state que se le proporciona a la función 
                if (e.id === panel.id && e.chart_type === panel.chart_type) {
                    return {
                        ...e, loading: state
                    }
                }
                return e
            })
            setPanelLoadingStatus(() => newState)
        }
    }

    function loadPanel(graph) {
        let dta
        let error
        dispatch(dashboardDataRequestThunk({id: graph.id, type: graph.chart_type})).unwrap().then((data) => {
            dta = [data.data]
            error = false
            insertGraph(graph, dta, error)
        }).catch((data) => {
            dta = []
            error = true
            dispatch(pushNotification(new NotificationModel(`Error cargando panel "${graph.name}"`,
                `HTTP error: ${data.http_status_code}, contacte con el administrador. soporte@espossible.com`,
                'error').toJson()))
            insertGraph(graph, dta, error)
        }).finally(() => {
            togglePanelLoadingStatus(graph, false)
            setLoadingGraphs(() => false)
        })
    }

    function insertGraph(graph, data, error) {
        const index = getNewGraphIndex(graph.id, graph.chart_type)
        
        if (index > -1) {
            const newState = newGraphsState.map((e) => {
                if (e.graph_info.id === graph.id && e.graph_info.chart_type === graph.chart_type) {
                    return {
                        ...e, graph_info: graph, data: data, error: error
                    }
                }
                return e
            })
            setNewGraphsState(() => [...newState])
        } else {
            setNewGraphsState((state) => [...state, {
                    graph_info: graph,
                    data: data,
                    error: error
                }]
            )
        }
    }

    function dispatchPanels() {
        dispatch(dashboardOptionsRequestThunk()).unwrap().then((e) => {
            if (e.length === 0) {
                setError(() => 'Dashboard')
            } else {
                setError(() => '')
            }
        }).catch(e => {
            dispatch(pushNotification(new NotificationModel('Error recargando los dashboards',
                `HTTP error: ${e.http_status_code}, contacte con el administrador. soporte@espossible.com`,
                'error').toJson()))
        }).finally(() => {
            setLoading(() => false)
        })
    }

    function loadDashboard() {
        const id = selectionsState.target.value
        setlocalState(() => ({charts: []}))
        if (!isNaN(id)) {
            const filteredPanel = dashboardStore.panels.find((e) => Number(e.id) === Number(id))
            if (filteredPanel && filteredPanel !== '') {
                if (filteredPanel.data.length === 0) {
                    setError(() => 'Panel')
                }
                for (const graph of filteredPanel.data) {
                    let target = document.querySelector(`#panel_placeholder-${graph.id}-${graph.chart_type}`);
                    observer.observe(target);
                }
            }
            setLoadingGraphs(() => false)
        }
    }

    function prepareData() {
        setlocalState(() => ({charts: []}))
        let charts = []
        for (const chart of newGraphsState) {
            let processed = {};
            let options = {};
            if(chart.graph_info.chart_type!="marker"){
                options = JSON.parse(JSON.stringify(optionsList[chart.graph_info.chart_type])); // Clonado profundo de las opciones
            }
            processed = prepareChart(chart, options);
            processed.opt.id = chart.graph_info.id;
            charts.push(new ChartModel(processed.opt.type, processed.opt, processed.dta, chart.error));
        }
        setlocalState(() => ({charts: [...charts]}))
    }


}

export default Index
