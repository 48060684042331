import {Button, Form, Loading, Select, SelectItem, TextInput} from "@carbon/react";
import {useEffect, useId, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {NotificationModel} from "../../../Models/Notification.js";
import {sourcesActionsRequestThunk} from "../../../store/reducers/DAToolReducer.js";
import {pushNotification} from "../../../store/reducers/notificationsReducer.js";
import {changeInput, dataSources, sanitizeItemInputs, toHTTPDownloader, validateForm} from "../../../Utils/utils";
import CronPicker from "../../CronPicker/index.js";

const file_types = [
    {key: 'json', name: 'JSON'},
    {key: 'jsonstat', name: 'JSON STAT'},
    {key: 'csv', name: 'CSV'},
]
const Index = ({item, inModal = false, output, parentSubmitState = undefined, setParentSubmitState = undefined}) => {
    let key = useId()
    key = item.id ? item.id : key
    const [localState, setLocalState] = useState({...dataSources})
    const [itemState, setItemState] = useState({...item})
    const [errorState, setErrorState] = useState({...dataSources})
    const [selectionState, setSelectionState] = useState('json')
    const [submitState, setSubmitState] = useState(false)
    const dispatch = useDispatch()
    const inputRef = useRef()

    useEffect(() => {
        if (!item || item === '') {
            setItemState(() => ({...dataSources}))
        }
        let nItem = {...item}
        if (nItem && nItem.url) {
            nItem["url@url"] = nItem.url.toString()
            delete nItem.url
        }
        setLocalState((state) => ({...state, ...sanitizeItemInputs(nItem)}))
    }, [item])

    useEffect(() => {
        if (itemState !== '' || itemState !== {}) {
            setLocalState((state) => ({...state, ...itemState}))
        }
    }, [itemState])
    useEffect(() => {
        if (inModal) {
            output(localState)
        }
    }, [localState])

    useEffect(() => {
        setLocalState((state) => ({...state, file_type: selectionState}))
    }, [selectionState])

    useEffect(()=>{
        if (parentSubmitState && validateForm(localState, setErrorState, ['id', 'file_type'])) {
            output(localState)
        } else {
            if (setParentSubmitState) {
                setParentSubmitState(() => false)
            }
        }
    },[parentSubmitState])
    let title =
        <h4 className="title" aria-label={itemState.name}>{itemState ? 'Edición' : 'Nuevo'} HTTP Downloader <span
            className='item-name'>{itemState.name}</span></h4>
    return (
        <div className="filemanager">
            {title}
            <div className="inner-content">
                <Form key={key}>
                    <div className={'form-content'}>
                        <TextInput
                            id={"name#" + key}
                            invalidText={errorState.name}
                            labelText="Nombre de la fuente de datos *"
                            placeholder="Introduce un nombre para la fuente de datos"
                            value={localState.name}
                            onChange={(event) => changeInput(event.target.id, event.target.value, setLocalState, setErrorState)}
                            ref={inputRef}
                            invalid={errorState.name !== ''}
                        />
                        <TextInput
                            type="url"
                            id={"url@url#" + key}
                            invalidText="URL no válida"
                            labelText="URL *"
                            placeholder="Introduce el origen para el HTTP Downloader (URL)"
                            value={localState["url@url"]}
                            onChange={(event) => changeInput(event.target.id, event.target.value, setLocalState, setErrorState)}
                            ref={inputRef}
                            invalid={errorState["url@url"] !== ''}
                        />
                        <Select
                            id={"select-1#" + key}
                            labelText="Seleccione el tipo de archivo"
                            size="lg"
                            onChange={(e) => setSelectionState(e.target.value)}
                            value={selectionState}
                            ref={inputRef}
                        >
                            {file_types.map((cron) => {
                                return <SelectItem key={cron.key}
                                                   text={`${cron.name.toUpperCase()}`}
                                                   value={cron.key}
                                />
                            })}
                        </Select>
                        <CronPicker item={itemState} saveCounter={submitState}
                                    output={changeInput} setParentState={setLocalState}
                                    setParentErrorState={setErrorState}></CronPicker>
                        {!inModal &&
                            <Button
                                kind="primary"
                                className={'button-loading'}
                                onClick={(e) => saveNewItem(e)}
                                disabled={submitState}
                            >
                                Guardar

                                {submitState &&
                                    <Loading
                                        description="Active loading indicator" withOverlay={false}
                                        small/>
                                }
                            </Button>}
                    </div>
                </Form>
            </div>
        </div>
    )

    function saveNewItem(e) {
        setSubmitState(() => true)
        e.preventDefault()

        if (validateForm(localState, setErrorState, ['id', 'file_type', 'global_type'])) {
            console.log("Este es el id de data:" + localState.id)
            const data = toHTTPDownloader(localState)
            if (localState.file_type === '') {
                setLocalState((state) => ({...state, file_type: inputRef.current.value}))
                data.file_type = inputRef.current.value
            } else {
                data.file_type = selectionState
            }
            dispatch(sourcesActionsRequestThunk({type: 'postDownloader', data})).then((r) => {
                if (r.error) {
                    dispatch(pushNotification(new NotificationModel('Error en la actualización',
                        `HTTP error: ${r.payload.http_status_code}, contacte con el administrador. soporte@espossible.com`,
                        'error').toJson()))
                    setLocalState(() => ({...sanitizeItemInputs(dataSources)}))
                } else {
                    output('saved')
                }
                setSubmitState(() => false)
            }).finally(() => {
                setLocalState(() => ({...sanitizeItemInputs(dataSources)}))
            })
        } else {
            setSubmitState(() => false)
        }
    }

}


export default Index
