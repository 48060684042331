import {useEffect, useRef, useState} from "react";
import {v4} from "uuid";
import {db_con_form_setup, formComponents} from "../../../../Utils/utils";

const db_types = [{key: 'MySQL', name: 'MySQL'}, {key: 'Postgre', name: 'Postgre'}]

const Index = ({item, output, parentErrorState, setParentErrorState, saveCounter}) => {
    const [localState, setLocalState] = useState({...db_con_form_setup})
    const [keyState, setKeyState] = useState(v4())
    const [inputState, setInputState] = useState([])
    const inputRef = useRef()
    useEffect(() => {
        if (saveCounter) {
            let localitem = {...item}
            localitem = {...reset()}
            setKeyState(() => v4())
            setLocalState((state) => ({...state, ...localitem}))
        }
    }, [saveCounter])
    useEffect(() => {
        let localitem = {...item}
        if (!item || Object.keys(item).length === 0) {
            localitem = reset()
        }
        setKeyState(() => v4())
        setLocalState((state) => ({...state, ...localitem}))
    }, [item])
    useEffect(() => {
        if (localState.db_type === '') {
            setLocalState((state) => ({...state, db_type: 'MySQL'}))
        } else {
            output(localState)
        }
        setInputState(() => formComponents(inputs, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [localState])
    useEffect(() => {
        setInputState(() => formComponents(inputs, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [localState.connection])
    useEffect(() => {
        setInputState(() => formComponents(inputs, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [parentErrorState])

    function reset() {
        let localItem = {...db_con_form_setup}
        localItem = {...localItem, db_type: 'MySQL'}
        return localItem
    }

    const inputs = [
        {
            type: 'group',
            inner: [
                {
                    type: 'text',
                    id: 'host',
                    invalidText: parentErrorState.connection.host,
                    labelText: 'Host de la base de datos',
                    placeholder: 'Introduce un nombre para el host de la base de datos',
                    value: localState.host,
                    invalid: parentErrorState.connection.host,
                    parentErrorName: 'connection',
                },
                {
                    type: 'text',
                    subType: 'number',
                    id: 'port@port',
                    invalidText: parentErrorState.connection["port@port"],
                    labelText: 'Puerto de la conexión',
                    placeholder: 'Introduce el puerto para la conexión con la base de datos',
                    value: localState["port@port"],
                    invalid: parentErrorState.connection["port@port"],
                    parentErrorName: 'connection',
                }
            ]
        },
        {
            type: 'group',
            inner: [
                {
                    type: 'text',
                    id: 'user',
                    invalidText: parentErrorState.connection.user,
                    labelText: 'Usuario de la base de datos',
                    placeholder: 'Introduce el nombre de usuario de la base de datos',
                    value: localState.user,
                    invalid: parentErrorState.connection.user,
                    parentErrorName: 'connection',
                },
                {
                    type: 'text',
                    subType: 'password',
                    id: 'password',
                    invalidText: parentErrorState.connection.password,
                    labelText: 'Contraseña de la base de datos',
                    placeholder: 'Introduce la contraseña de la base de datos',
                    value: localState.password,
                    invalid: parentErrorState.connection.password,
                    parentErrorName: 'connection',
                },
            ]
        },
        {
            type: 'group',
            inner: [
                {
                    type: 'select',
                    id: 'db_type',
                    invalidText: parentErrorState.connection.db_type,
                    labelText: 'Tipo de base de datos',
                    placeholder: 'Introduce el tipo de base de datos',
                    value: localState.db_type,
                    invalid: parentErrorState.connection.db_type,
                    parentErrorName: 'connection',
                    types: db_types
                },
                {
                    type: 'text',
                    id: 'db_name',
                    invalidText: parentErrorState.connection.db_name,
                    labelText: 'Nombre de la fase de datos',
                    placeholder: 'Introduce el nombre de la base de datos',
                    value: localState.db_name,
                    invalid: parentErrorState.connection.db_name,
                    parentErrorName: 'connection',
                }
            ]
        },
    ];

    return (
        <div className="dbcon">
            <div className="inner-content">
                <div className={'form-content'}>
                    {inputState}
                </div>
            </div>
        </div>
    )
}


export default Index
