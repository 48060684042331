import { Save, TaskAdd, TrashCan } from "@carbon/icons-react";
import { Button, ButtonSet, Form } from "@carbon/react";
import { useEffect, useId, useRef, useState } from "react";
import { v4 } from "uuid";
import {
    formComponents,
    isFormEmpty,
    joinPhase,
    sanitizeItemInputs,
    toJoinPhase,
    validateForm
} from "../../../../../Utils/utils.js";

const join_types = [
    { key: 'INNER', name: 'INNER' },
    { key: 'LEFT', name: 'LEFT' },
    { key: 'RIGHT', name: 'RIGHT' },
    { key: 'OUTER', name: 'OUTER' },
]
const Index = ({ item, inModal = false, output, itemCount, parentTargets }) => {
    let key = useId()
    key = item.id ? item.id : key
    const tempId = key
    const [keyState, setKeyState] = useState(v4())
    const [localState, setLocalState] = useState({ ...joinPhase })
    const [itemState, setItemState] = useState({ ...item })
    const [targetState, setTargetState] = useState([])
    const [errorState, setErrorState] = useState({ ...joinPhase })
    const inputRef = useRef()
    const inputs = [
        {
            type: 'text',
            id: 'name',
            invalidText: errorState.name,
            labelText: 'Nombre de la fase',
            placeholder: 'Introduce un nombre para la fase de sanitización',
            value: localState.name,
            invalid: errorState.name,
        },
        {
            type: 'combo',
            id: 'table_A_data_source',
            invalidText: errorState.table_A_data_source,
            labelText: 'Origen',
            placeholder: 'Seleccione el origen de los datos',
            value: localState.table_A_data_source,
            invalid: errorState.table_A_data_source,
            types: targetState,
            disabled: targetState.length === 0
        },
        {
            type: 'select',
            id: 'join_type',
            invalidText: errorState.join_type,
            labelText: 'Tipo de Join',
            placeholder: 'Seleccione el tipo de union',
            value: localState.join_type,
            invalid: errorState.join_type,
            types: join_types
        },
        {
            type: 'text',
            id: 'join_key_A',
            invalidText: errorState.join_key_A,
            labelText: 'Clave del Join A',
            placeholder: 'Introduce la clave del Join A',
            value: localState.join_key_A,
            invalid: errorState.join_key_A
        },
        {
            type: 'combo',
            id: 'table_B_data_source',
            invalidText: errorState.table_B_data_source,
            labelText: 'Origen secundario',
            placeholder: 'Seleccione el origen secundario',
            value: localState.table_B_data_source,
            invalid: errorState.table_B_data_source,
            types: targetState,
            disabled: targetState.length === 0
        },
        {
            type: 'text',
            id: 'table_B_key',
            invalidText: errorState.table_B_key,
            labelText: 'Clave del Join B',
            placeholder: 'Introduce la clave del Join B',
            value: localState.table_B_key,
            invalid: errorState.table_B_key
        },
        {
            type: 'text',
            id: 'output_column',
            invalidText: errorState.output_column,
            labelText: 'Elija la columna que desea crear con los resultados del Join',
            placeholder: 'Dejar en blanco para sobrescribir',
            value: localState.output_column,
            invalid: errorState.output_column
        },
    ];
    let createInputs = formComponents(inputs, setLocalState, setErrorState, localState, errorState, inputRef, keyState)
    useEffect(() => {
        if (parentTargets) {
            // let items = parentTargets.split(',').map((e) => ({id: e, text: e}))
            setTargetState(() => [...parentTargets])
            setErrorState((state) => ({ ...state, target: '' }))
        } else {
            setTargetState(() => [])
            // setErrorState((state) => ({ ...state, target: 'Seleccione los orígenes en el pipeline' })) 
            setLocalState((state) => ({ ...state, target: '' }))
        }
    }, [parentTargets])
    useEffect(() => {
        if (!item || item === '') {
            setItemState(() => ({ ...joinPhase, join_type: 'INNER' }))
        }
        setKeyState(() => v4())
        setLocalState((state) => ({ ...state, ...sanitizeItemInputs(itemState) }))
    }, [item])
    useEffect(() => {
        setLocalState((state) => ({ ...state, ...sanitizeItemInputs(itemState) }))
        createInputs = formComponents(inputs, setLocalState, setErrorState, localState, errorState, inputRef, keyState)
    }, [itemState])
    let title =
        <h4 className="title" aria-label={itemState.name}>{itemState ? 'Edición' : 'Nuevo'} Fase Join <span
            className='item-name'>{itemState.name}</span></h4>
    return (
        <div className="joinPhase">
            {title}
            <div className="inner-content">
                <Form key={key}>
                    <div className={'form-content'}>
                        {createInputs}
                        {!inModal &&
                            <ButtonSet className={'action-buttons'}>
                                <Button kind={'danger'} renderIcon={TrashCan}
                                    onClick={() => deleteItem()}>Eliminar</Button>
                                <Button kind={'primary'} renderIcon={!isFormEmpty(item) ? Save : TaskAdd}
                                    onClick={() => saveNewItem()}>{!isFormEmpty(item) ? 'Actualizar' : 'Añadir'}</Button>
                            </ButtonSet>
                        }

                    </div>
                </Form>
            </div>
        </div>
    )

    function saveNewItem() {
        if (validateForm(localState, setErrorState, ['id', 'global_type', 'order', 'tempId', 'output_column'])) {
            let data = toJoinPhase(localState)
            data.order = itemCount + 1
            if (item === '') {
                if (data.id === '' && data.tempId === '') {
                    data = { ...data, tempId: tempId }
                }
                output(data, 'addPhase')
            } else {
                output(data, 'editPhase')
            }
            setLocalState(() => ({ ...sanitizeItemInputs(joinPhase) }))
            setItemState(() => ({ ...sanitizeItemInputs(joinPhase) }))
        }
    }

    function deleteItem() {
        if (item !== '') {
            const data = toJoinPhase(localState)
            output(data, 'deletePhase')
        }
        setLocalState(() => ({ ...sanitizeItemInputs(joinPhase) }))
        setErrorState(() => ({ ...sanitizeItemInputs(joinPhase) }))
    }

}


export default Index
