import React from 'react';
import { Tag } from '@carbon/react';
import {toNumber} from "../../../Utils/chartUtils";


const Marker = ({ value, tag }) => {

    //const width = `${value.length * 10}px`;

    const markerContainerStyle = {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    };
  
    const markerStyle = {
      width: '100px',
      height: '50px',
      //borderRadius: '0%',
      backgroundColor: 'orange',
      color: 'white',
      fontSize: '16px',
      margin: '5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    };
  
    const tagStyle = {
      fontSize: '12px',
      color: 'orange'
    };
  
    return (
      <div style={markerContainerStyle}>
        <div style={markerStyle}>
          {value}
        </div>
        <Tag style={tagStyle}>{tag}</Tag>
      </div>
    );
  };

  const Index = ({ chart }) => {
        console.log(chart)
  
        return (
            <div>
                <Marker value={chart.data[0].value} tag={chart.data[0].tag} />
            </div>
          );
  };

export default Index