import {Information} from "@carbon/icons-react";
import {ListItem, Theme, Toggletip, ToggletipButton, ToggletipContent, UnorderedList} from "@carbon/react";

const Index = ({info = []}) => {
    let items = info.map((el, index) => {
        return <ListItem key={`list-item#${index}`}>{el}</ListItem>
    })
    return (
        <div>
            {items.length > 0 &&
                <Toggletip>
                    <ToggletipButton label="Información adicional">
                        <Information/>
                    </ToggletipButton>
                    <ToggletipContent>
                        <Theme theme={'g10'}>
                            <UnorderedList>
                                {items}
                            </UnorderedList>
                        </Theme>
                    </ToggletipContent>
                </Toggletip>}
        </div>
    )

}


export default Index
