import React, {useEffect, useRef, useState} from 'react';
import {v4} from "uuid";
import {data_types, formComponents, parameters, sanitizeItemInputs} from "../../../../../../Utils/utils.js";
import Categorical from "./Categorical/index.js";



function Index({item, setParentErrorState, parentErrorState, output, reset, setReset}) {
    const [keyState, setKeyState] = useState(v4())
    const inputRef = useRef()
    const [inputState, setInputState] = useState([])
    const [localState, setLocalState] = useState({...parameters})
    const [conditionalInputsState, setConditionalInputsState] = useState([])
    const [conditionalInputitemsState, setConditionalInputItemsState] = useState([])
    const [inputItemsState, setInputItemsState] = useState([...recalculateInputs()])
    const [componentInputState, setComponentInputState] = useState([])

    function recalculateInputs() {
        return [{
            type: 'combo',
            id: 'enforce_type',
            invalidText: parentErrorState.parameters.enforce_type,
            labelText: 'Tipo de forzado de las variables',
            placeholder: 'Selecciona el tipo de la variable a la que desea forzar',
            value: localState.enforce_type,
            invalid: parentErrorState.parameters.enforce_type,
            types: data_types,
            parentErrorName: 'parameters'
        }]
    }

    useEffect(() => {
        let localitem = {...item}
        if (!item || Object.keys(item).length === 0) {
            localitem = {...parameters}
        }
        setKeyState(() => v4())
        setLocalState((state) => ({...state, ...sanitizeItemInputs(localitem)}))
        enforceChooser()
    }, [item])
    useEffect(() => {
        output(localState)
        setInputItemsState(() => [...recalculateInputs()])
    }, [localState])
    useEffect(() => {
        setInputItemsState(() => [...recalculateInputs()])
    }, [parentErrorState])
    useEffect(() => {
        setConditionalInputsState(()=>[])
        setComponentInputState(()=>[])
        enforceChooser()
        setInputState(() => formComponents(inputItemsState, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [inputItemsState])

    useEffect(() => {
        if (reset) {
            setLocalState(() => ({...parameters}))
            setReset(() => false)
        }
    }, [reset])

    useEffect(() => {
        setConditionalInputsState(() => formComponents(conditionalInputitemsState, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [conditionalInputitemsState])

    function enforceChooser() {
        switch (localState.enforce_type) {
            case 'DATE':
                setConditionalInputItemsState(() => [{
                    type: 'text',
                    id: 'date_format',
                    invalidText: parentErrorState.parameters.date_format,
                    labelText: 'Formato de fecha',
                    placeholder: 'Introduce el formato de fecha',
                    value: localState.date_format,
                    invalid: parentErrorState.parameters.date_format,
                    parentErrorName: 'parameters'
                }])
                break;
            case 'CATEGORICAL':
                setComponentInputState(() => formChooser())
                break;
            default:
                setConditionalInputItemsState(() => [])
                setComponentInputState(() => [])
                break
        }
    }

    const formTypes = {
        'CATEGORICAL': <Categorical item={item ? item : ''}
                                    enforceType={'CATEGORICAL'}
                                    output={(returnData) => setLocalState((state) => ({...state, ...returnData}))}
                                    setParentErrorState={setParentErrorState}
                                    parentErrorState={parentErrorState}
                                    reset={reset} setReset={setReset}></Categorical>,
    }

    function formChooser() {
        return formTypes[localState.enforce_type]
    }

    return (
        <div>
            {inputState}
            {conditionalInputsState}
            {componentInputState}
        </div>
    );
}

export default Index;
