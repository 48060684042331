import {Loading} from "@carbon/react";

const Index = ({panel}) => {
    return (
        <div id={`panel_placeholder-${panel.id}-${panel.chart_type}`} className='panel-placeholder'>
            <h2>Cargando Gráfica</h2>
            <h3>{panel.name}</h3>
            <Loading
                description="Active loading indicator" withOverlay={false}
            />
        </div>
    )
}
export default Index
