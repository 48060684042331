import {Button, Form, Loading} from "@carbon/react";
import {useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {v4} from "uuid";
import {NotificationModel} from "../../../Models/Notification.js";
import {sourcesActionsRequestThunk} from "../../../store/reducers/DAToolReducer.js";
import {pushNotification} from "../../../store/reducers/notificationsReducer.js";
import {
    arrayToCommaString,
    changeInput, db_con_form_setup,
    db_form_setup,
    formComponents,
    sanitizeItemInputs,
    toDBSource,
    validateForm
} from "../../../Utils/utils";
import CronPicker from "../../CronPicker/index.js";
import DatabaseConnection from "./DatabaseConnection/index.js";

const Index = ({item, inModal = false, output, parentSubmitState = undefined, setParentSubmitState = undefined}) => {
    const [keyState, setKeyState] = useState(v4())
    const [localState, setLocalState] = useState({...db_form_setup})
    const [itemState, setItemState] = useState({...item})
    const [errorState, setErrorState] = useState({...db_form_setup})
    const [submitState, setSubmitState] = useState(false)
    const [inputState, setInputState] = useState({main: [], configTable: []})
    const [inputItemsState, setInputItemsState] = useState({...recalculateInputs()})
    const [connectionState, setConnectionState] = useState({...db_con_form_setup})
    const dispatch = useDispatch()
    const inputRef = useRef()

    function recalculateInputs() {
        return {
            main: [{
                type: 'text',
                id: 'name',
                invalidText: errorState.name,
                labelText: 'Nombre de la fuente de datos',
                placeholder: 'Introduce un nombre para la fuente de datos',

                value: localState.name,
                invalid: errorState.name
            }],
            configTable: [{
                type: 'text',
                id: 'table',
                invalidText: 'Nombre de la tabla no válido',
                labelText: 'Nombre de la tabla *',
                placeholder: 'Introduce un nombre para la tabla',
                value: localState.table,
                invalid: errorState.table
            }, {
                type: 'text',
                id: 'columns',
                invalidText: errorState.columns,
                labelText: 'Columnas de la tabla',
                placeholder: 'Introduce los nombres de las columnas separadas por comas o dejarla vacía para utilizar todas las columnas',
                value: arrayToCommaString(localState.columns),
                invalid: errorState.columns
            },],
        }
    }

    useEffect(() => {
        setKeyState(() => v4())
        if (itemState === '' || itemState === {}) {
            setItemState(() => ({...db_form_setup}))
        } else {
            let nItem = {...item}
            if (nItem && nItem.connection && nItem.connection.port) {
                nItem.connection = {...nItem.connection, ...item.connection}
                nItem.connection["port@port"] = nItem.connection.port.toString()
                delete nItem.connection.port
            }
            setItemState((state) => ({...state, ...sanitizeItemInputs(nItem)}))
        }
    }, [item])
    useEffect(() => {
        if (itemState !== '' || itemState !== {}) {
            setLocalState((state) => ({...state, ...itemState}))
        }
    }, [itemState])
    useEffect(() => {
        if (inModal) {
            output(localState)
        }
        setInputItemsState(() => ({...recalculateInputs()}))
    }, [localState])
    useEffect(() => {
        if (parentSubmitState && validateForm(localState, setErrorState, ['id', 'type', 'columns'])) {
            output(localState)
        } else {
            if (setParentSubmitState) {
                setParentSubmitState(() => false)
            }
        }
    }, [parentSubmitState])
    let title = <h4 className="title" aria-label={itemState.name}>{itemState ? 'Edición' : 'Nueva'} Base de datos <span
            className='item-name'>{itemState.name}</span></h4>
    useEffect(() => {

        setInputState((state) => ({
            ...state,
            main: formComponents(inputItemsState.main, setLocalState, setErrorState, localState, errorState, inputRef, keyState)
        }))
        setInputState((state) => ({
            ...state,
            configTable: formComponents(inputItemsState.configTable, setLocalState, setErrorState, localState, errorState, inputRef, keyState)
        }))
    }, [localState, errorState, inputItemsState])
    useEffect(() => {
        changeInput("connection", connectionState, setLocalState, setErrorState, localState, errorState)
    }, [connectionState])

    return (<>{localState && <div className="dbimport">
        {title}
        <div className="inner-content">
            <Form key={keyState}>
                <div className={'form-content'}>
                    {inputState.main}
                    <div className={'form-block'}>
                        <h4>Configuración de la conexión</h4>
                        <DatabaseConnection item={itemState ? itemState.connection : ''}
                                            output={(returnData) => setConnectionState((prevState) => ({...prevState, ...returnData}))}
                                            saveCounter={submitState}
                                            setParentState={setLocalState}
                                            setParentErrorState={setErrorState}
                                            parentErrorState={errorState}
                                            parentState={localState}/>
                    </div>
                    <div className={'form-block form-content'}>
                        <h4>Configuración de la tabla</h4>
                        {inputState.configTable}
                    </div>
                    <div className={'form-block'}>
                        <h4>Periodicidad</h4>
                        <CronPicker item={itemState}
                                    saveCounter={submitState}
                                    output={changeInput} setParentState={setLocalState}
                                    setParentErrorState={setErrorState}/>
                    </div>
                    {!inModal && <Button
                        kind="primary"
                        className={'button-loading'}
                        onClick={(e) => saveNewItem(e)}
                        disabled={submitState}
                    >
                        Guardar

                        {submitState && <Loading
                            description="Active loading indicator" withOverlay={false}
                            small/>}
                    </Button>}
                </div>
            </Form>
        </div>
    </div>}</>)

    function saveNewItem(e) {
        setSubmitState(() => true)
        e.preventDefault()
        if (validateForm(localState, setErrorState, ['id', 'type', 'columns'])) {
            const data = toDBSource(localState)
            data.type = 'json'
            dispatch(sourcesActionsRequestThunk({type: 'postDBSource', data})).then((r) => {
                if (r.error) {
                    dispatch(pushNotification(new NotificationModel('Error en la actualización', `HTTP error: ${r.payload.http_status_code}, contacte con el administrador. soporte@espossible.com`, 'error').toJson()))
                    setLocalState(() => ({...sanitizeItemInputs(db_form_setup)}))
                    setItemState(() => ({...db_form_setup}))
                } else {
                    output('saved')
                }
                setSubmitState(() => false)
            }).catch().finally(() => {
                setLocalState(() => ({...sanitizeItemInputs(db_form_setup)}))
            })
        } else {
            setSubmitState(() => false)
        }
    }

}


export default Index
