function isBlank(str) {
    return (!str || /^(?=\s*$)/.test (str));
}

function cleanBaseUrl(base_url_str){
    if (isBlank(base_url_str)){
        return 'http://localhost/api'
    }
    // remove trailing slash in case
    return base_url_str.replace(/\/+$/, '')
}

export const BASE_BACKEND_URL = cleanBaseUrl(
    window.__RUNTIME_CONFIG__.PUBLIC__API_SERVER_URL
)