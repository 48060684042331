import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    isAdminRequest,
    profileRequest,
    profileUpdateRequest,
    tokenRefresh,
    tokenRequest
} from '../../apiClient/userRequests'


// First, create the thunk
export const tokenRequestThunk = createAsyncThunk(
    'user/tokenRequest',
    async (loginForm, thunkAPI) => {
        try {
            let res = await tokenRequest(loginForm);
            return thunkAPI.fulfillWithValue(res.data)
        } catch (err) {
            // hidrate error information on failed request
            return thunkAPI.rejectWithValue({
                http_status_code: err.response.status
            })
        }
    }
)

export const tokenRefreshThunk = createAsyncThunk(
    'user/tokenRefresh',
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        try {
            let res = await tokenRefresh({ refresh: state.user.token_refresh });
            return thunkAPI.fulfillWithValue(res.data)
        } catch (err) {
            // hidrate error information on failed request
            console.error(err)

            return thunkAPI.rejectWithValue({
                http_status_code: err.response?.status
            })
        }
    }
)

export const profileRequestThunk = createAsyncThunk(
    'user/profile',
    async (_, thunkAPI) => {
        try {
            let res = await profileRequest(thunkAPI);
            return thunkAPI.fulfillWithValue(res.data)
        } catch (err) {
            // hidrate error information on failed request
            return thunkAPI.rejectWithValue({
                http_status_code: err.response.status
            })
        }
    }
)
export const profileUpdateThunk = createAsyncThunk(
    'user/profile/update',
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState()
            let res = await profileUpdateRequest(thunkAPI, state.user.profile);
            return thunkAPI.fulfillWithValue(res.data)
        } catch (err) {
            // hidrate error information on failed request
            return thunkAPI.rejectWithValue({
                http_status_code: err.response.status
            })
        }
    }
)
export const isAdminThunk = createAsyncThunk(
    '/api/is_admin/',
    async (_, thunkAPI) => {
        try {
            let res = await isAdminRequest(thunkAPI);
            return thunkAPI.fulfillWithValue(res.data)
        } catch (err) {
            // hidrate error information on failed request
            return thunkAPI.rejectWithValue({
                http_status_code: err.response.status
            })
        }
    }
)

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        loading: false,
        currentRequestId: '',
        token_access: localStorage.getItem('token_access'),
        token_refresh: localStorage.getItem('token_refresh'),
        error: '',
        profile: {},
        username: '',
        isAdmin: undefined
    },
    reducers: {
        logout: (state) => {
            localStorage.removeItem('token_access')
            localStorage.removeItem('token_refresh')
            document.cookie = `sessionid=; expires=Sat, 20 Jan 1980 12:00:00 UTC`
            document.cookie = `csrftoken=; expires=Sat, 20 Jan 1980 12:00:00 UTC`
            state.token_access = undefined
            state.token_refresh = undefined
            state.error = ''
            state.isAdmin = ''
            state.loading = false
            window.location.replace('/login');
        },
        updateErrorState: (state) => {
            state.error = ''
        },
        updateProfileState: (state, action) => {
            let profile = { ...state.profile }
            profile.user = { ...profile.user, ...action.payload }
            state.profile = { ...state.profile, ...profile }
        },
        updateUsernameState: (state, action) => {
            state.username = action.payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(tokenRequestThunk.pending, (state, action) => {
            if (!state.loading) {
                state.loading = true
                state.currentRequestId = action.meta.requestId
            }
        })
        builder.addCase(tokenRequestThunk.fulfilled, (state, action) => {
            setLogged(state, action)
        })
        builder.addCase(tokenRequestThunk.rejected, (state, action) => {
            state.error = action.payload?.http_status_code ?
                `http_error_${action.payload.http_status_code}` : 'server_error'
            state.loading = false
            state.token_refresh = ''
        })
        builder.addCase(tokenRefreshThunk.fulfilled, (state, action) => {
            setLogged(state, action)
        })
        builder.addCase(tokenRefreshThunk.rejected, (state, action) => {
            setRejected(state, action)
            state.token_refresh = ''
        })
        builder.addCase(profileRequestThunk.fulfilled, (state, action) => {
            if (action.payload.length > 0) {
                state.profile = action.payload[0]
            } else {
                state.profile = {}
            }
            state.loading = false
        })
        builder.addCase(profileRequestThunk.rejected, (state, action) => {
            state.error = action.payload?.http_status_code ?
                `http_error_${action.payload.http_status_code}` : 'server_error'
            state.loading = false
        })
        builder.addCase(profileUpdateThunk.fulfilled, (state, action) => {
            state.profile = action.payload[0]
            state.loading = false
        })
        builder.addCase(profileUpdateThunk.rejected, (state, action) => {
            setRejected(state, action)
        })

        builder.addCase(isAdminThunk.fulfilled, (state, action) => {
            if (state.isAdmin === '') {
                if (action.payload.length > 0) {
                    state.isAdmin = action.payload[0].is_admin
                } else {
                    state.isAdmin = ''
                }
            }
        })
        builder.addCase(isAdminThunk.rejected, (state) => {
            state.isAdmin = ''
        })
    }
})

function setRejected(state, data) {
    state.error = data.payload?.http_status_code ?
        `http_error_${data.payload.http_status_code}` : 'server_error'
    state.loading = false
}

function setLogged(state, data) {
    localStorage.setItem('token_access', data.payload.access)
    state.token_access = localStorage.getItem('token_access')

    if (data.payload.refresh) {
        localStorage.setItem('token_refresh', data.payload.refresh)
        state.token_refresh = localStorage.getItem('token_refresh')
    }

    state.error = ''
    state.loading = false
}

// Action creators are generated for each case reducer function
export const { logout, updateErrorState, updateProfileState, updateUsernameState } = userSlice.actions
export default userSlice.reducer
