import {Column, FlexGrid, Heading, Row} from "@carbon/react";
import Profile from "../../components/Profile";

const ProfileLayout = () => {
    return (
        <div className="page-container">
            <FlexGrid narrow={true}>
                <Column>
                    <Row>
                        <Heading className="">Perfil</Heading>
                    </Row>
                    <Row>
                        <Profile></Profile>
                    </Row>
                </Column>
            </FlexGrid>
        </div>
    )
}
export default ProfileLayout
