import React, {useEffect, useRef, useState} from 'react';
import {v4} from "uuid";
import {data_types_select, formComponents, parameters, sanitizeItemInputs} from "../../../../../../Utils/utils.js";

function Index({item, setParentErrorState, parentErrorState, output, reset, setReset}) {
    const [keyState, setKeyState] = useState(v4())
    const inputRef = useRef()
    const [inputState, setInputState] = useState([])
    const [localState, setLocalState] = useState({...parameters})
    const [conditionalInputsState, setConditionalInputsState] = useState([])
    const [conditionalInputitemsState, setConditionalInputItemsState] = useState([])
    const [inputItemsState, setInputItemsState] = useState([...recalculateInputs()])

    function recalculateInputs() {
        return [
            {
                type: 'text',
                id: 'value_to_compare',
                invalidText: parentErrorState.parameters.value_to_compare,
                labelText: 'Valor con el que comparar',
                placeholder: 'Introduce el valor con el que comparar',
                value: localState.value_to_compare,
                invalid: parentErrorState.parameters.value_to_compare,
                parentErrorName: 'parameters'
            },
            {
                type: 'select',
                id: 'filter_data_type',
                invalidText: parentErrorState.parameters.filter_data_type,
                labelText: 'Tipo de reemplazo',
                value: localState.filter_data_type,
                invalid: parentErrorState.parameters.filter_data_type,
                types: data_types_select.filter((e) => e.key !== 'CATEGORICAL'),
                parentErrorName: 'parameters'
            },
        ]
    }

    useEffect(() => {
        let localitem = {...item}
        if (!item || Object.keys(item).length === 0) {
            localitem = {...parameters}
            localitem.filter_data_type = 'STRING'
        }
        setKeyState(() => v4())
        setLocalState((state) => ({...state, ...sanitizeItemInputs(localitem)}))
        enforceChooser()
    }, [item])
    useEffect(() => {
        output(localState)
        setInputItemsState(() => [...recalculateInputs()])
    }, [localState])
    useEffect(() => {
        setInputItemsState(() => [...recalculateInputs()])
    }, [parentErrorState])
    useEffect(() => {
        enforceChooser()
        setInputState(() => formComponents(inputItemsState, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [inputItemsState])

    useEffect(() => {
        if (reset) {
            setLocalState(() => ({...parameters}))
            setReset(() => false)
        }
    }, [reset])

    useEffect(() => {
        setConditionalInputsState(() => formComponents(conditionalInputitemsState, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [conditionalInputitemsState])

    function enforceChooser() {
        switch (localState.filter_data_type) {
            case 'DATE':
                setConditionalInputItemsState(() => [{
                    type: 'text',
                    id: 'date_format',
                    invalidText: parentErrorState.parameters.date_format,
                    labelText: 'Índice de columnas',
                    placeholder: 'Introduce el índice de columnas',
                    value: localState.date_format,
                    invalid: parentErrorState.parameters.date_format,
                    parentErrorName: 'parameters'
                }])
                break;
            default:
                setConditionalInputItemsState(() => [])
                break
        }
    }


    return (
        <div>
            {inputState}
            {conditionalInputsState}
        </div>
    );
}

export default Index;
