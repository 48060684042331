import { Settings } from "@carbon/icons-react";
import { Button, Content } from "@carbon/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationModel } from "../../../Models/Notification.js";
import { sourcesActionsRequestThunk } from "../../../store/reducers/DAToolReducer.js";
import { pushNotification } from "../../../store/reducers/notificationsReducer.js";
import TimeLine from "./TimeLine/index.js";

const Index = ({ data, title, output }) => {
    const dispatch = useDispatch();
    const datoolStore = useSelector((state) => state.datool)
    const [showState, setShowState] = useState(false);
    const [selectedPhase, setSelectedPhase] = useState({ id: 0, title: '' });
    const [loading, setLoading] = useState(false)
    const [sourceItems, setSourceItems] = useState([])

    useEffect(() => {
        if (selectedPhase.id !== 0) {
            setShowState(true)
        }
    }, [selectedPhase])

    useEffect(() => {
        setLoading(() => true)
        // loadDataSources()
    }, [])

    const actionSelector = {
        createItem: (returnData) => {
            createItem(returnData)
        },
        updateItem: (returnData) => {
            updateItem(returnData)
        }, deleteItem: (returnData) => {
            deleteItem(returnData)
        },

    }


    return (<div className={'pipeline'}>
        <div className={'pipeline-header'}>
            <h2>{title}</h2>
            <Button hasIconOnly={true} renderIcon={Settings} iconDescription={'Ajustes Pipeline'}
                onClick={() => output({ action: 'update', data })}></Button>
        </div>
        <div className={''}>
            <TimeLine
                phasesJoin={data.join_steps}
                phasesSanitize={data.sanitization_steps}
                phasesKdd={data.kdd_steps}>
            </TimeLine>
        </div>
    </div>)

    function actionChooser(data, action) {
        actions[action](data)
    }


    function handleSelection(phase) {
        setSelectedPhase((state) => ({ ...state, ...phase }))
    }

    /**
     * Loads sources data into store
     */
    function loadDataSources() {
        dispatch(sourcesActionsRequestThunk({ type: 'getSources', id: '' })).then()
            .catch((error) => {
                dispatch(pushNotification(new NotificationModel('Error en la carga',
                    `HTTP error: ${error.http_status_code}, contacte con el administrador. soporte@espossible.com`,
                    'error').toJson()))
            }).finally(setLoading(() => false))
    }

    function updateItem(data) {
        console.log('Edit', data)
        loadDataSources()
    }

    function deleteItem(data) {
        console.log('Delete', data)
        loadDataSources()
    }

    function createItem(data) {
        console.log('New', data)
        // loadDataSources()
    }

    /**
     * Function that returns sources with their id updated with id+type
     * @param sources
     * @returns {Object[]}
     */
    function uniqueIdForTable(sources) {
        let processed_sources = [...sources]
        processed_sources.forEach((e) => {
            e.id = `${e.id}@${e.global_type}`
        })
        return processed_sources
    }
}
const actions = {
    addPhase: (data) => {
        console.log(data);
    }
}
export default Index
