import Marker from "../components/Panels/Marker";
import BarChart from "../components/Panels/Charts/BarChart";
import GroupedBarChart from "../components/Panels/Charts/GroupedBarChart";
import AreaChart from "../components/Panels/Charts/AreaChart";
import LineChart from "../components/Panels/Charts/LineChart";
import PieChart from "../components/Panels/Charts/PieChart";
import GaugeChart from "../components/Panels/Charts/GaugeChart";
import HistogramChart from "../components/Panels/Charts/HistogramChart";
import LollipopChart from "../components/Panels/Charts/LollipopChart";
import ScatterChart from "../components/Panels/Charts/ScatterChart";
import SparklineChart from "../components/Panels/Charts/SparklineChart";
import HeatMapChart from "../components/Panels/Charts/HeatMapChart";
import TablePanel from "../components/Panels/TablePanel";
import CounterPanel from "../components/Panels/CounterPanel";
import StackedBarChart from "../components/Panels/Charts/StackedBarChart";
import MeterProportionalChart from "../components/Panels/Charts/MeterProportionalChart";
import BoxplotChart from "../components/Panels/Charts/BoxplotChart";
import DonutChart from "../components/Panels/Charts/DonutChart";
import BubbleChart from "../components/Panels/Charts/BubbleChart";
import ExperimentalChoroplethChart from "../components/Panels/Charts/ExperimentalChoroplethChart";

import {
    areaDefaultOptions,
    barDefaultOptions,
    boxplotDefaultOptions, bubbleDefaultOptions,
    donutDefaultOptions,
    gaugeDefaultOptions,
    groupedBarDefaultOptions,
    heatMapDefaultOptions,
    histogramDefaultOptions,
    lineDefaultOptions, lollipopDefaultOptions, meterProportionalDefaultOptions,
    pieDefaultOptions, scatterDefaultOptions, sparklineDefaultOptions,
    stackedBarDefaultOptions, experimentalChoroplethDefaultOptions
} from "../components/Panels/Charts/chartsDefaults";

const chartElements = {
    'marker': (chart) => <Marker chart={chart}></Marker>,
    'bar_chart': (chart) => <BarChart chart={chart}></BarChart>,
    'groupedbar_chart': (chart) => <GroupedBarChart chart={chart}></GroupedBarChart>,
    'stackedbar_chart': (chart) => <StackedBarChart chart={chart}></StackedBarChart>,
    'area_chart': (chart) => <AreaChart chart={chart}></AreaChart>,
    'line_chart': (chart) => <LineChart chart={chart}></LineChart>,
    'pie_chart': (chart) => <PieChart chart={chart}></PieChart>,
    'gauge_chart': (chart) => <GaugeChart chart={chart}></GaugeChart>,
    'donut_chart': (chart) => <DonutChart chart={chart}></DonutChart>,
    'boxplot_chart': (chart) => <BoxplotChart chart={chart}></BoxplotChart>,
    'histogram_chart': (chart) => <HistogramChart chart={chart}></HistogramChart>,
    'heatmap_chart': (chart) => <HeatMapChart chart={chart}></HeatMapChart>,
    'meter_proportional_chart': (chart) => <MeterProportionalChart chart={chart}></MeterProportionalChart>,
    'lollipop_chart': (chart) => <LollipopChart chart={chart}></LollipopChart>,
    'scatter_chart': (chart) => <ScatterChart chart={chart}></ScatterChart>,
    'sparkline_chart': (chart) => <SparklineChart chart={chart}></SparklineChart>,
    'bubble_chart': (chart) => <BubbleChart chart={chart}></BubbleChart>,
    'table_chart': (chart) => <TablePanel chart={chart}></TablePanel>,
    'counter_chart': (chart) => <CounterPanel chart={chart}></CounterPanel>,
    'experimental_choropleth_chart': (chart) => <ExperimentalChoroplethChart chart={chart}></ExperimentalChoroplethChart>
}

export function chartChooser(chart) {
    return chartElements[chart.type](chart)
}

export function toNumber(chart) {
    return [...chart.data.map(e => {
        return {...e, value: e.value !== null ? Number(e.value) : null}
    })]
}

export function genericOptions(chart, defaultOptions, chartType) {
    let opt = {}
    if (defaultOptions !== 'custom_panel') {
        opt = {...defaultOptions}
        switch (chartType) {
            case 'marker':
                break
            case 'lollipop_chart' :
                opt.axes.left.title = chart.graph_info.y_label ? chart.graph_info.y_label.toUpperCase() : chart.graph_info.y_columns.map((a) => a.column_name.toUpperCase())
                opt.axes.bottom.title = chart.graph_info.x_label ? chart.graph_info.x_label.toUpperCase() : chart.graph_info.x_column_name.toUpperCase()
                break
            case 'boxplot_chart':
                opt.axes.bottom.title = chart.graph_info.y_label ? chart.graph_info.y_label.toUpperCase() : chart.graph_info.y_columns.map((a) => a.column_name.toUpperCase())
                opt.axes.left.title = chart.graph_info.x_label ? chart.graph_info.x_label.toUpperCase() : chart.graph_info.x_column_name.toUpperCase()
                break
            case 'pie_chart':
                break
            case 'gauge_chart':
                break
            case 'donut_chart':
                opt.donut.center.label = chart.graph_info.y_label ? chart.graph_info.y_label.toUpperCase() : chart.graph_info.y_columns.map((a) => a.column_name.toUpperCase())
                break
            case 'histogram_chart':
                opt.axes.left.title = 'Counter'
                opt.axes.bottom.title = chart.graph_info.y_label ? chart.graph_info.y_label.toUpperCase() : chart.graph_info.y_columns.map((a) => a.column_name.toUpperCase())
                break
            case 'heatmap_chart':
                opt.axes.left.title = chart.graph_info.y_columns[1].column_name
                opt.axes.bottom.title = chart.graph_info.y_columns[0].column_name
                break
            case 'meter_proportional_chart':
                break
            case 'experimental_choropleth_chart':
                break
            default:
                opt.axes.left.title = chart.graph_info.y_label ? chart.graph_info.y_label.toUpperCase() : chart.graph_info.y_columns.map((a) => a.column_name.toUpperCase())
                opt.axes.bottom.title = chart.graph_info.x_label ? chart.graph_info.x_label.toUpperCase() : chart.graph_info.x_column_name.toUpperCase()
                break
        }
    }
    return opt
}

export function prepareChart(chart, defaultOptions) {
    const opt = genericOptions(chart, defaultOptions, chart.graph_info.chart_type)

    opt.title = chart.graph_info.title && chart.graph_info.title !== '' ? chart.graph_info.title.toUpperCase() : chart.graph_info.name.toUpperCase()
    opt.type = chart.graph_info.chart_type

    opt.subtitle = chart.graph_info.subtitle ? chart.graph_info.subtitle : ''
    opt.description = chart.graph_info.description ? convertToHTML(chart.graph_info.description) : ''
    opt.tooltip = chart.graph_info.tooltip ? convertToHTML(chart.graph_info.tooltip) : ''
    opt.user_description = chart.graph_info.user_description ? convertToHTML(chart.graph_info.user_description) : ''
    const y_columns = () => {
        if (chart.graph_info.y_columns) {
            return [...chart.graph_info.y_columns];
        }
        return []
    }
    opt.col_desc = [{
        column_name: chart.graph_info.x_column_name,
        description: chart.graph_info.x_column_description,
        column_title: chart.graph_info.x_column_title
    }, ...y_columns()]
    let dta = []
    if (chart.data.length > 0 && chart.data[0]) {
        switch (defaultOptions) {
            case 'custom_panel':
                switch (chart.graph_info.chart_type) {
                    case 'table_chart':
                        opt.headers = chart.data[0].headers
                        dta = chart.data[0].rows
                        break
                    case 'counter_chart':
                        dta = chart.data[0]
                        break
                }
                break
            case gaugeDefaultOptions:
                dta = chart.data
                break
            default:
                if (chart.data.length === 1) {
                    if (chart.data[0] && chart.data[0].length > 0) {
                        dta = [...chart.data[0]]
                    } else {
                        dta = [...chart.data]
                    }
                } else {
                    dta = [...chart.data]
                }
                break
        }
    }
    
    return {opt, dta}
}

export function convertToHTML(data) {
    return <div dangerouslySetInnerHTML={{__html: data}}></div>

}

export function getAlternativeName(chart, name) {
    let alt = chart.options.col_desc.find((a) => a.column_name === name)
    if (alt && alt.column_title !== '')
        return alt.column_title
    return name
}

export const optionsList = {
    'bar_chart': barDefaultOptions,
    'groupedbar_chart': groupedBarDefaultOptions,
    'stackedbar_chart': stackedBarDefaultOptions,
    'line_chart': lineDefaultOptions,
    'area_chart': areaDefaultOptions,
    'pie_chart': pieDefaultOptions,
    'gauge_chart': gaugeDefaultOptions,
    'donut_chart': donutDefaultOptions,
    'boxplot_chart': boxplotDefaultOptions,
    'histogram_chart': histogramDefaultOptions,
    'heatmap_chart': heatMapDefaultOptions,
    'meter_proportional_chart': meterProportionalDefaultOptions,
    'sparkline_chart': sparklineDefaultOptions,
    'lollipop_chart': lollipopDefaultOptions,
    'scatter_chart': scatterDefaultOptions,
    'bubble_chart': bubbleDefaultOptions,
    'table_chart': 'custom_panel',
    'counter_chart': 'custom_panel',
    'experimental_choropleth_chart': experimentalChoroplethDefaultOptions,
}
