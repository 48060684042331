import {Button, FileUploaderDropContainer, FileUploaderItem, Loading} from "@carbon/react";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {addFile, removeFile, uploadFile} from "../../../Utils/utils.js";

const Index = ({item = {}, types, output, inModal = false}) => {
    const dispatch = useDispatch()
    const [fileState, setFileState] = useState({...item, ...{filename: '', file: ''}})
    const [submitState, setSubmitState] = useState(false)
    useEffect(() => {
        removeFile(setFileState)
    }, [item])
    useEffect(() => {
        if (fileState.file !== '' && fileState.filename !== '' && inModal) {
            output(fileState)
        }
    }, [fileState])
    useEffect(() => {
        if (!submitState) {
            removeFile(setFileState)
        }
    }, [submitState])
    return (
        <div className='file-uploader-container'>
            <p className="cds--label-description">
                Formatos admitidos: CSV
            </p>
            <div className={'file-uploader-area'}>
                <FileUploaderDropContainer
                    disabled={submitState}
                    accept={types}
                    labelText="Para subir el archivo, arrastre y suelte o haga click aquí"
                    name=""
                    multiple={false}
                    onAddFiles={(e, files) => addFile(e, files.addedFiles, setFileState, types)}
                    role=""
                >

                </FileUploaderDropContainer>
                <div
                    className="cds--file-container"
                    style={{
                        width: '100%'
                    }}
                >
                    {fileState.filename !== '' &&
                        <FileUploaderItem name={fileState.filename}
                                          onDelete={() => !submitState ? removeFile(setFileState) : false}
                                          status={'edit'}></FileUploaderItem>
                    }
                </div>

            </div>
            {!inModal &&
                <Button
                    kind="primary"
                    className={'button-loading'}
                    onClick={() => uploadFile(fileState, setSubmitState, setFileState, dispatch, output)}
                    disabled={submitState}
                >
                    Guardar
                    {submitState &&
                        <Loading
                            description="Active loading indicator" withOverlay={false}
                            small/>
                    }
                </Button>}
        </div>
    )
}

export default Index
