import { NotificationModel } from "../Models/Notification.js";
import { sourcesActionsRequestThunk, pipelinesActionsRequestThunk } from "../store/reducers/DAToolReducer.js";
import { pushNotification } from "../store/reducers/notificationsReducer.js";

/**
 * Loads sources data into store
 */
export function loadDataSources(setLoading, dispatch) {
    dispatch(sourcesActionsRequestThunk({ type: 'getSources', id: '' })).then()
        .catch((error) => {
            dispatch(pushNotification(new NotificationModel('Error en la carga',
                `HTTP error: ${error.http_status_code}, contacte con el administrador. soporte@espossible.com`,
                'error').toJson()))
        }).finally(setLoading(() => false))
}

/**
 * Loads pipelines into store
 */
export function loadPipelines(setLoading, dispatch) {
    dispatch(pipelinesActionsRequestThunk({ type: 'getPipelines', id: '' })).then()
        .catch((error) => {
            dispatch(pushNotification(new NotificationModel('Error en la carga',
                `HTTP error: ${error.http_status_code}, contacte con el administrador. soporte@espossible.com`,
                'error').toJson()))
        }).finally(() => setLoading(() => false))
}