import React, { useEffect, useRef, useState } from 'react';
import { v4 } from "uuid";
import { formComponents, parameters_kdd, sanitizeItemInputs } from "../../../../../../Utils/utils.js";

export const date_operation_choose = [
    { id: 'Quarter', text: 'Quarter' },
    { id: 'Hour', text: 'Hour' },
    { id: 'Week', text: 'Week' },
    { id: 'Month', text: 'Month' },
    { id: 'Year', text: 'Year' },
    { id: 'Day of week', text: 'Day of week' },
    { id: 'Day of month', text: 'Day of month' },
]
export const categorical_type_choose = [
    { id: 'True', text: 'True' },
    { id: 'False', text: 'False' },
]

function Index({ item, setParentErrorState, parentErrorState, output, reset, setReset }) {
    const [keyState, setKeyState] = useState(v4())
    const inputRef = useRef()
    const [inputState, setInputState] = useState([])
    const [localState, setLocalState] = useState({ ...parameters_kdd })
    const [conditionalInputsState, setConditionalInputsState] = useState([])
    const [conditionalInputitemsState, setConditionalInputItemsState] = useState([])
    const [inputItemsState, setInputItemsState] = useState([...recalculateInputs()])

    function recalculateInputs() {
        return [
            {
                type: 'combo',
                id: 'date_operation',
                invalidText: parentErrorState.parameters_kdd.date_operation,
                labelText: 'Tipo de operación de fecha',
                placeholder: 'Selecciona la operación de fecha',
                value: localState.date_operation,
                invalid: parentErrorState.parameters_kdd.date_operation,
                types: date_operation_choose,
                parentErrorName: 'parameters_kdd'
            },
            {
                type: 'combo',
                id: 'date_is_categorical',
                invalidText: parentErrorState.parameters_kdd.date_is_categorical,
                labelText: '¿Es categórico?',
                placeholder: 'Selecciona la operación es categórico',
                value: localState.date_is_categorical,
                invalid: parentErrorState.parameters_kdd.date_is_categorical,
                types: categorical_type_choose,
                parentErrorName: 'parameters_kdd'
            },
            {
                type: 'text',
                subType: 'number',
                id: 'date_cut_points',
                invalidText: parentErrorState.parameters_kdd.date_cut_points,
                labelText: 'Puntos de corte (Puede estar vacío)',
                placeholder: 'Puntos de corte para discretizar la variable, solo internos en función añadimos -inf y +Inf',
                value: localState.date_cut_points,
                invalid: parentErrorState.parameters_kdd.date_cut_points,
                parentErrorName: 'parameters_kdd'
            },
        ]
    }

    useEffect(() => {
        let localitem = { ...item }
        if (!item || Object.keys(item).length === 0) {
            localitem = { ...parameters_kdd }
        }
        setKeyState(() => v4())
        setLocalState((state) => ({ ...state, ...sanitizeItemInputs(localitem) }))
        enforceChooser()
    }, [item])
    useEffect(() => {
        output(localState)
        setInputItemsState(() => [...recalculateInputs()])
    }, [localState])
    useEffect(() => {
        setInputItemsState(() => [...recalculateInputs()])
    }, [parentErrorState])
    useEffect(() => {
        setConditionalInputsState(() => [])
        enforceChooser()
        setInputState(() => formComponents(inputItemsState, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [inputItemsState])

    useEffect(() => {
        if (reset) {
            setLocalState(() => ({ ...parameters_kdd }))
            setReset(() => false)
        }
    }, [reset])

    useEffect(() => {
        setConditionalInputsState(() => formComponents(conditionalInputitemsState, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [conditionalInputitemsState])

    function enforceChooser() {
        if (localState.date_cut_points !== 0 && localState.date_cut_points !== '' && localState.date_cut_points !== '0' && localState.date_cut_points !== undefined) {
            setConditionalInputItemsState(() => [{
                type: 'text',
                id: 'date_label_array',
                invalidText: parentErrorState.parameters_kdd.date_label_array,
                labelText: 'Conjunto de etiquetas',
                placeholder: 'En caso de que utilice puntos de corte, puede definir sus etiquetas. necesitamos len(puntos_corte)+1 etiquetas',
                value: localState.date_label_array,
                invalid: parentErrorState.parameters_kdd.date_label_array,
                parentErrorName: 'parameters_kdd'
            }])
        }
        else{
            setConditionalInputItemsState(() => [])
        }
    }

    return (
        <div>
            {inputState}
            {conditionalInputsState}
        </div>
    );
}

export default Index;
