import { Save, TaskAdd, TrashCan } from "@carbon/icons-react";
import { Button, ButtonSet } from "@carbon/react";
import { useEffect, useId, useRef, useState } from "react";
import { v4 } from "uuid";
import {
    formComponents,
    isFormEmpty,
    sanitizeItemInputs,
    kddPhase,
    toJoinPhase,
    tokddPhase,
    validateForm,
    validationListKDD
} from "../../../../../Utils/utils.js";
import KddForms from "../KddForms/index.js";

const kdd_types = [
    { id: 'AGGREGATION', text: 'AGGREGATION' }, /* Working properly */
    { id: 'CORRELATION', text: 'CORRELATION' }, /* Working properly */
    { id: 'HYPOTESIS TEST', text: 'HYPOTESIS TEST' }, /* Working properly */
    { id: 'COUNT FILTER', text: 'COUNT FILTER' }, /* Working properly */
    { id: 'COLUMNS OPERATION', text: 'COLUMNS OPERATION' }, /* Working properly */
    { id: 'DATE OPERATIONS', text: 'DATE OPERATIONS' }, /* Working properly */
    { id: 'REDUCE DATASET', text: 'REDUCE DATASET' }, /* Working properly */
    { id: 'CUSTOM KDD', text: 'CUSTOM KDD' }, /* Working properly */
]
const Index = ({ item, output, reset, setReset, itemCount, parentTargets }) => {
    let key = useId()
    key = item.id ? item.id : key
    const tempId = key
    const [keyState, setKeyState] = useState(v4())
    const [localState, setLocalState] = useState({ ...kddPhase })
    const [itemState, setItemState] = useState({ ...item })
    const [errorState, setErrorState] = useState({ ...kddPhase })
    const [kddFormState, setKddFormState] = useState({ ...kddPhase })
    const [inputState, setInputState] = useState([])
    const [targetState, setTargetState] = useState([])
    const [inputItemsState, setInputItemsState] = useState([...recalculateInputs()])
    const inputRef = useRef()

    function recalculateInputs() {
        return [
            {
                type: 'text',
                id: 'name',
                invalidText: errorState.name,
                labelText: 'Nombre de la fase',
                placeholder: 'Introduce un nombre para la fase KDD',
                value: localState.name,
                invalid: errorState.name,
            },
            {
                type: 'combo',
                id: 'kdd_type',
                invalidText: errorState.kdd_type,
                labelText: 'Tipo de KDD',
                placeholder: 'Seleccione el tipo de KDD',
                value: localState.kdd_type,
                invalid: errorState.kdd_type,
                types: kdd_types
            }
        ]
    }

    useEffect(() => {
        if (parentTargets) {
            // let items = parentTargets.split(',').map((e) => ({ id: e.id, text: e.text }))
            setTargetState(() => [...parentTargets])
            setErrorState((state) => ({ ...state, target: '' }))
        } else {
            setTargetState(() => [])
            // setErrorState((state) => ({ ...state, target: 'Seleccione los orígenes en el pipeline' }))
            setLocalState((state) => ({ ...state, target: '' }))
        }
    }, [parentTargets])

    useEffect(() => {
        setInputItemsState(() => [...recalculateInputs()])
    }, [targetState])

    useEffect(() => {
        if (!item || item === '') {
            setItemState(() => ({ ...kddPhase, kdd_type: '' }))
        }
        setKeyState(() => v4())
        setLocalState((state) => ({ ...state, ...sanitizeItemInputs(itemState) }))
    }, [item])
    useEffect(() => {
        setInputItemsState(() => [...recalculateInputs()])
    }, [localState])
    useEffect(() => {
        setLocalState((state) => ({ ...state, ...sanitizeItemInputs(itemState) }))
    }, [itemState])
    useEffect(() => {
        setInputState(() => formComponents(inputItemsState, setLocalState, setErrorState, localState, errorState, inputRef, keyState))
    }, [inputItemsState])
    useEffect(() => {
        const filteredData = { ...kddFormState }
        delete filteredData.id
        delete filteredData.name
        delete filteredData.target
        delete filteredData.kdd_type
        filteredData.order = itemCount + 1
        //TODO: Here
        // console.log('errorState: ', errorState);
        // setErrorState(() => ({ ...sanitizeItemInputs(kddPhase) }))
        setLocalState((state) => ({ ...state, ...filteredData }))
    }, [kddFormState])

    useEffect(() => {
        if (reset) {
            setLocalState((state) => ({ ...state, kdd_type: '' }))
            setReset(() => false)
        }
    }, [reset])
    return (<div className="kddPhase">
        <div className="inner-content">
            <div className={'form-content'}>
                {inputState}
                {localState.kdd_type !== '' &&
                    <KddForms item={item ? item : ''}
                        output={(returnData) => setKddFormState((prevState) => ({ ...prevState, ...returnData }))}
                        parentErrorState={errorState}
                        setParentErrorState={setErrorState}
                        type={localState.kdd_type}
                        reset={reset} setReset={setReset}
                    />}
                {localState.kdd_type !== '' &&
                    <ButtonSet className={'action-buttons'}>
                        <Button kind={'danger'} renderIcon={TrashCan}
                            onClick={() => deleteItem()}>Eliminar</Button>
                        <Button kind={'primary'} renderIcon={!isFormEmpty(item) ? Save : TaskAdd}
                            onClick={() => saveNewItem()}>{!isFormEmpty(item) ? 'Actualizar' : 'Añadir'}</Button>
                    </ButtonSet>}

            </div>
        </div>
    </div>)
    function saveNewItem() {
        if (validateParameters()) {
            let data = tokddPhase(localState)
            if (item === '') {
                if (data.id === '' && data.tempId === '') {
                    data = { ...data, tempId: tempId }
                }
                output(data, 'addPhase')
            } else {
                output(data, 'editPhase')
            }
            setLocalState(() => ({ ...sanitizeItemInputs(kddPhase) }))
            setItemState(() => ({ ...sanitizeItemInputs(kddPhase) }))
        }
        //TODO: If not valid, refresh parent components
        console.log('ErrorState: ', errorState);
    }

    function validateParameters() {
        let noValidate = Object.keys(localState.parameters_kdd).filter((e) => !validationListKDD(localState).includes(e))
        noValidate = [...noValidate, 'id', 'kdd_type', 'tempId', "order", 'input_column', 'output_column']
        return validateForm(localState, setErrorState, noValidate)
    }

    function deleteItem() {
        if (item !== '') {
            const data = toJoinPhase(localState)
            output(data, 'deletePhase')
        }
        setLocalState(() => ({ ...sanitizeItemInputs(kddPhase) }))
        setErrorState(() => ({ ...sanitizeItemInputs(kddPhase) }))
    }

}


export default Index
