import { Edit, TrashCan } from "@carbon/icons-react";
import {
    Button,
    ComposedModal,
    DataTable,
    Loading,
    ModalBody,
    ModalFooter,
    OverflowMenu,
    OverflowMenuItem,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableHeader,
    TableRow,
    TableToolbar
} from "@carbon/react";
import { compare } from "@carbon/react/lib/components/DataTable/tools/sorting.js";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4, } from 'uuid';
import { NotificationModel } from "../../../Models/Notification.js";
import { sourcesActionsRequestThunk } from "../../../store/reducers/DAToolReducer.js";
import { addModal, closeModal, toggleModal } from "../../../store/reducers/modalsReducer.js";
import { pushNotification } from "../../../store/reducers/notificationsReducer.js";
import {
    getModalById,
    sanitizeInput, splitIdandType,
    toDBSource,
    toHTTPDownloader,
    uploadFile,
    validateForm,
    isFormEmpty
} from "../../../Utils/utils.js";
import DBImport from "../DBImport/index.js";
import FileManager from "../FileManager/index.js";
import FileUploader from "../FileUploader/index.js";
import ModalStateManager from "../../Modal/index.js";
import ToolBar from "../../Table/ToolBar/index.js";

const Index = ({ data, title, output, notForTable = [], editAction = 'modal' }) => {
    let header = [...prepareHeadersFromStringList(Object.keys(data[0]))]
    const modalEdit = 'modalEditItemDataSource'
    const modalDelete = 'modalDeleteItemDataSource'
    const dispatch = useDispatch();
    const modals = useSelector((state) => state.modals)
    const [rowsState, setRowsState] = useState(sanitizeInput([...data], notForTable))
    const [dataState, setDataState] = useState([...data])
    const [newState, setNewState] = useState({})
    const [errorState, setErrorState] = useState({})
    const [selectedState, setSelectedState] = useState({})
    const [submitState, setSubmitState] = useState(false)

    useEffect(() => {
        resetSelection()
        header = [...prepareHeadersFromStringList(Object.keys(data[0]))]
        setRowsState(() => sanitizeInput([...data], notForTable))
    }, [data])
    useEffect(() => {
        dispatch(addModal(modalEdit))
        dispatch(addModal(modalDelete))

    }, [modals])

    useEffect(() => {
        if (selectedState.action === 'delete') {
            if (editAction === 'modal') {
                dispatch(toggleModal(modalDelete));
            } else {
                output(selectedState, 'deleteItem')
            }
        }
        if (selectedState.action === 'edit') {
            if (editAction === 'modal') {
                dispatch(toggleModal(modalEdit));
            } else {
                output(selectedState, 'updateItem')
            }
        }
    }, [selectedState])

    function resetSelection() {
        setSelectedState(() => ({}))
        setErrorState(() => ({}))
        setNewState(() => ({}))
    }

    const editTypes = {
        downloader: (<FileManager key={'edit' + selectedState.id} item={selectedState} inModal={true}
            output={(e) => setNewState((state) => ({ ...state, ...e }))}
            parentSubmitState={submitState} setParentSubmitState={setSubmitState}></FileManager>),
        db: (<DBImport key={'edit' + selectedState.id} item={selectedState} inModal={true}
            output={(e) => setNewState((state) => ({ ...state, ...e }))}
            parentSubmitState={submitState} setParentSubmitState={setSubmitState}></DBImport>),
        file: <FileUploader types={['.csv']} key={'edit' + selectedState.id} item={selectedState} inModal={true}
            output={(e) => setNewState((state) => ({ ...state, ...e }))}
            parentSubmitState={submitState} setParentSubmitState={setSubmitState}></FileUploader>
    }

    const editModalService = ModalStateManager(
        {
            children:
                <>
                    <ComposedModal className={'dataSource'}
                        preventCloseOnClickOutside={true}
                        open={getModalById(modals, modalEdit) ? getModalById(modals, modalEdit).state : false}
                        onClose={() => dispatch(closeModal(modalEdit))} size="md">
                        <ModalBody>
                            {
                                editTypes[selectedState.global_type]
                            }
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                kind="danger"
                                onClick={() =>
                                    dispatch(closeModal(modalEdit))
                                }>
                                Cancelar
                            </Button>
                            <Button
                                kind="primary"
                                onClick={() => {
                                    updateDataSource()
                                }}
                                disabled={submitState}>
                                Guardar
                                {submitState &&
                                    <Loading
                                        description="Active loading indicator" withOverlay={false}
                                        small />
                                }
                            </Button>
                        </ModalFooter>
                    </ComposedModal>
                </>
        }
    )
    const deleteModalService = ModalStateManager(
        {
            children:
                <>
                    <ComposedModal className={'dataSource'}
                        preventCloseOnClickOutside={true}
                        open={getModalById(modals, modalDelete) ? getModalById(modals, modalDelete).state : false}
                        onClose={() => dispatch(closeModal(modalDelete))} size="md">
                        <ModalBody>
                            <div>
                                <h1>¿Desea eliminar el siguiente origen de datos?</h1>
                                <div className={'edit-container'}>
                                    <div className={'edit-info-row'}><h5>ID: </h5>
                                        <span>{splitIdandType(selectedState.id).id_part}</span></div>
                                    <div className={'edit-info-row'}><h5>NOMBRE: </h5>
                                        <span>{sanitizeCell(selectedState.name)}</span></div>
                                    <div className={'edit-info-row'}><h5>TIPO: </h5>
                                        <span>{splitIdandType(selectedState.id).type_part}</span></div>
                                    <div className={'edit-info-row'}><h5>PERIODICIDAD: </h5>
                                        <span>{sanitizeCell(selectedState.periodicity)}</span>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                kind="primary"
                                onClick={() => {
                                    dispatch(closeModal(modalDelete))
                                }}
                                disabled={submitState}>
                                Cancelar

                            </Button>
                            <Button
                                kind="danger"
                                onClick={() =>
                                    deleteDataSource()
                                }>
                                Eliminar
                                {submitState &&
                                    <Loading
                                        description="Active loading indicator" withOverlay={false}
                                        small />
                                }
                            </Button>
                        </ModalFooter>
                    </ComposedModal>
                </>
        }
    )

    if (rowsState)
        return (
            <div className='source-table'>
                <DataTable rows={rowsState} headers={header} isSortable
                    sortRow={(cellA, cellB, {
                        sortDirection,
                        sortStates,
                        locale
                    }) => customSortRow(cellA, cellB, { sortDirection, sortStates, locale })}>
                    {({ rows, headers, getHeaderProps, getTableProps }) => (
                        <TableContainer title={title}>
                            <TableToolbar>
                                <ToolBar data={data} notForTable={notForTable} setState={setRowsState}></ToolBar>
                            </TableToolbar>

                            <Table {...getTableProps()}>
                                <TableHead>
                                    <TableRow>
                                        {showRowWithoutId().map((header) => (
                                            <TableHeader {...getHeaderProps({ header })}>
                                                {header.header}
                                            </TableHeader>
                                        ))}
                                        <TableHeader scope={'col'}></TableHeader>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {showElementsWithoutId(rows, data).map((row) => (
                                        <TableRow key={getRowId(row.id.toString())}>
                                            {row.cells.map((cell) =>
                                            (<TableCell
                                                key={`${cell.id.toString()}`}>{sanitizeCell(cell.value)}</TableCell>)
                                            )}
                                            <TableCell className="cds--table-column-menu">
                                                <OverflowMenu
                                                    flipped
                                                    size={'md'}
                                                    ariaLabel={'Editar'}
                                                >
                                                    <OverflowMenuItem
                                                        onClick={() => openEdit(row.id, 'edit')}
                                                        itemText={<span className={'row-action'}><Edit />Editar</span>}
                                                    >Editar</OverflowMenuItem>
                                                    <OverflowMenuItem
                                                        isDelete
                                                        onClick={() => openDelete(row.id, 'delete')}
                                                        itemText={<span
                                                            className={'row-action'}><TrashCan />Eliminar</span>}
                                                    ></OverflowMenuItem>
                                                </OverflowMenu>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </DataTable>
                {editModalService}
                {deleteModalService}
            </div>
        )
    else {
        return (
            <div>

            </div>
        )
    }

        /**
     * Function that reformar periodicity column
     * @param periodicity
     * @returns {String}
     */
    function reformatPeriodicity(periodicity){
        if (periodicity === "@once"){
            periodicity = "Una vez"
        }else if(periodicity === "@hourly"){
            periodicity = "Cada hora"
        }else if(periodicity === "@daily"){
            periodicity = "Cada día"
        }else if(periodicity === "@weekly"){
            periodicity = "Cada semana"
        }else if(periodicity === "@monthly"){
            periodicity = "Cada mes"
        }else if(periodicity === "@yearly"){
            periodicity = "Cada año"
        }else{
            let periodicity_aux
            periodicity_aux = periodicity.split(" ")
            if (periodicity_aux.length === 6){
                let stringToReturn = ""
                periodicity_aux = periodicity_aux[4].split(",")
                for (var i = 0; i < periodicity_aux.length; i++){
                    if (i === periodicity_aux.length - 1){  
                        if (periodicity_aux[i] === '1'){
                            stringToReturn = stringToReturn + " y lunes"
                        }if (periodicity_aux[i] === '2'){
                            stringToReturn = stringToReturn + " y martes"
                        }if (periodicity_aux[i] === '3'){
                            stringToReturn = stringToReturn + " y miércoles"
                        }if (periodicity_aux[i] === '4'){
                            stringToReturn = stringToReturn + " y jueves"
                        }if (periodicity_aux[i] === '5'){
                            stringToReturn = stringToReturn + " y viernes"
                        }if (periodicity_aux[i] === '6'){
                            stringToReturn = stringToReturn + " y sábado"
                        }if (periodicity_aux[i] === '7'){
                            stringToReturn = stringToReturn + " y domingo"
                        }
                    }else{
                        if (periodicity_aux[i] === '1'){
                            stringToReturn = stringToReturn + ", lunes"
                        }if (periodicity_aux[i] === '2'){
                            stringToReturn = stringToReturn + ", martes"
                        }if (periodicity_aux[i] === '3'){
                            stringToReturn = stringToReturn + ", miércoles"
                        }if (periodicity_aux[i] === '4'){
                            stringToReturn = stringToReturn + ", jueves"
                        }if (periodicity_aux[i] === '5'){
                            stringToReturn = stringToReturn + ", viernes"
                        }if (periodicity_aux[i] === '6'){
                            stringToReturn = stringToReturn + ", sábado"
                        }if (periodicity_aux[i] === '7'){
                            stringToReturn = stringToReturn + ", domingo"
                        }
                    }
                }
                periodicity = stringToReturn.slice(2)
            }
        }
        return periodicity
    }

    /**
     * Function that returns Data Sources elements without id
     * @param element
     * @returns {Object}
     */
    function showElementsWithoutId(element, data) {
        let aux_cell_with_file_type
        let aux_periocity
        let aux_periocity_1

        //reformat periodicity
        for (var i = 0; i < element.length; i++){
            aux_periocity = element[i].cells[2]
            if (aux_periocity.value !== undefined){
                aux_periocity_1 = aux_periocity.value.start_date.replace("T", " - ").replace(":00Z", "")
                aux_periocity.value.start_date = aux_periocity_1
                element[i].cells[2].value.interval = reformatPeriodicity(element[i].cells[2].value.interval)  
            }

            //add column type
            aux_cell_with_file_type = element[i].cells[0]
            aux_cell_with_file_type.value = data[i].global_type
            element[i].cells.push(aux_cell_with_file_type)

            //delete column id
            element[i].cells.shift()
        }
        return element
    }


    /**
     * Function that returns Data Sources headers without id
     * @param headers
     * @returns {Object}
     */
    function showRowWithoutId() {
        return [{"header": "Nombre", "key": "Nombre"},{"header": "Periodicidad", "key": "Periodicidad"},{"header": "Tipo de archivo", "key": "Tipo de archivo"}]
    }



    /**
     * Function that returns sanitized cell
     * @param cell
     * @returns {string}
     */
    function sanitizeCell(cell) {
        let val = ''
        if (typeof cell === 'object') {
            Object.keys(cell).forEach((v, index) => {
                val += cell[v] ? cell[v] + (cell[Object.keys(cell)[index + 1]]
                    && cell[Object.keys(cell)[index + 1]] !== '' && Object.keys(cell).length > index ? ' => ' : '') : ''
            })
            return val ? val : ''
        } else {
            return cell ? cell : ''
        }
    }

    /**
     * Returns the row id composed by the original id and UUIDv4 generated string
     * @param {number} id
     * @returns {string}
     */
    function getRowId(id) {
        return `${id}@${uuidv4()}`
    }

    /**
     * Function that updates a data source
     * Depending on the source, it performs different calls to the backend
     */
    function updateDataSource() {
        setSubmitState(() => true)
        console.log(newState);
        if (!isFormEmpty(newState, putSource[newState && newState.global_type ? newState.global_type : 'emptyValidation'].noValidate)) {
            if (Object.keys(newState).includes('filename')) {
                uploadFile({ ...newState }, setSubmitState, setNewState, dispatch, output, 'putUploader', modalEdit)
            } else {
                if (validateForm(newState, setErrorState, putSource[newState.global_type].noValidate)) {
                    const data = formatForBackend[newState.global_type]({ ...newState })
                    data.id = splitIdandType(newState.id).id_part
                    dispatch(sourcesActionsRequestThunk({
                        type: putSource[newState.global_type].func,
                        id: splitIdandType(newState.id).id_part,
                        data: data
                    })).then((r) => {
                        if (r.error) {
                            dispatch(pushNotification(new NotificationModel('Error en la actualización',
                                `HTTP error: ${r.payload.http_status_code}, contacte con el administrador. soporte@espossible.com`,
                                'error').toJson()))
                        }

                        dispatch(toggleModal(modalEdit))
                        setSubmitState(() => false)
                        setNewState(() => ({}))
                        output(newState, 'updateItem')
                        setSelectedState(() => ({}))

                    })
                }
            }
        } else {
            setSubmitState(() => false)
        }
    }

    /**
     * Function that updates a data source
     * Depending on the source, it performs different calls to the backend
     */
    function deleteDataSource() {
        setSubmitState(() => true)
        dispatch(sourcesActionsRequestThunk({
            type: deleteSource[selectedState.global_type].func,
            id: splitIdandType(selectedState.id).id_part,
        })).then((r) => {
            if (r.error) {
                dispatch(pushNotification(new NotificationModel('Error en el borrado',
                    `HTTP error: ${r.payload.http_status_code}, contacte con el administrador. soporte@espossible.com`,
                    'error').toJson()))
            }

            dispatch(toggleModal(modalDelete))
            setSubmitState(() => false)
            output(selectedState, 'deleteItem')
            setSelectedState(() => ({}))
        })
    }

    /**
     * Returns an item filtered by the given id and type
     * @param {string} id
     * @returns {Object}
     */
    function getItem(id) {
        return dataState.find((d) => (d.id === id))
    }

    /**
     * Opens edit modal for the selected row
     * @param {string} id
     * @param {string} action
     */
    function openEdit(id, action) {
        setSelectedState(() => ({ ...getItem(parseInt(id)), action }));
        // if (editAction === 'modal') {
        //     dispatch(toggleModal(modalEdit));
        // } else {
        //     output(selectedState, 'updateItem')
        // }
    }


    /**
     * Opens delete modal for the selected row
     * @param {string} id
     * @param {string} action
     */
    function openDelete(id, action) {
        setSelectedState(() => ({ ...getItem(parseInt(id)), action }));
        // if (editAction === 'modal') {
        //     dispatch(toggleModal(modalDelete));
        // } else {
        //     output(newState, 'updateItem')
        // }
    }

    /**
     * + Returns a new list filtered for the table header
     * @param list
     * @returns {object[]}
     */
    function prepareHeadersFromStringList(list) {
        let newL = [...list.map((h) => {
            return { header: h, key: h }
        })]
        return newL.filter((e) => !notForTable.includes(e.header))
    }

    /**
     * Function that is used to sort the table
     * @param cellA
     * @param cellB
     * @param sortDirection
     * @param sortStates
     * @param locale
     * @returns {number}
     */
    function customSortRow(cellA, cellB, { sortDirection, sortStates, locale }) {
        if (sortDirection === sortStates.DESC) {
            return compare(sanitizeCell(cellB), sanitizeCell(cellA), locale);
        }
        return compare(sanitizeCell(cellA), sanitizeCell(cellB), locale);
    }

}
const formatForBackend = {
    file: (data) => {
        return ''
    },
    downloader: (data) => {
        return toHTTPDownloader(data)
    },
    db: (data) => {
        return toDBSource(data)
    },
}

const putSource = {
    downloader: {
        noValidate: ['id', 'type', 'global_type'],
        func: 'putDownloader'
    },
    db: {
        noValidate: ['id', 'type', 'columns', 'global_type'],
        func: 'putDBSource'
    },
    file: {
        noValidate: [],
        func: 'putUploader'
    },

    emptyValidation: {
        noValidate: []
    },
}
const deleteSource = {
    downloader: {
        noValidate: [],
        func: 'deleteDownloader'
    },
    db: {
        noValidate: [],
        func: 'deleteDBSource'
    },
    file: {
        noValidate: [],
        func: 'deleteUploader'
    },

}
export default Index
