import logo from './Logo-colmena-blanco-1.png'; // with import
import { Map, Email, Phone, LogoLinkedin, LogoYoutube, LogoTwitter, LogoFacebook } from "@carbon/icons-react";

const FooterLayout = () => (
    <footer>
        <div className="footer-big-block">
            <div className="inner-footer">
                <div className="footer-big-inner-block">
                    <div className="footer-element-general">
                        <section>
                            <div>
                                <a href="https://colmenadata.com/" target="_blank"><img className="img-footer-logo" src={logo} /></a>
                            </div>
                        </section>
                    </div>
                    <div className="footer-element-general">
                        <section>
                            <h2>Contacto</h2>
                            <br/>
                            <div>
                                <p><Phone />   +34-********* </p>
                                <p><Email /><a href="mailto:support@possible.com">   support@possible.com</a></p>
                            </div>
                        </section>
                    </div>
                    <div className="footer-element-general">
                        <section>
                            <h2>Redes Sociales</h2>
                            <br />
                            <div>
                                <a href="#"><LogoFacebook size={32} /></a> <a href="#"><LogoTwitter size={32} /></a> <a href="#"><LogoYoutube size={32} /></a> <a href="#"><LogoLinkedin size={32} /></a>
                            </div>
                        </section>
                    </div>
                    <div className="footer-element-general">
                        <section>
                            <h2>FAQ</h2>
                            <br />
                            <p>------------</p>
                        </section>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-block-1">
            <div className="inner-footer">
                <div className="footer-block-2">
                    <div className="footer-element-general footer-copyright">
                        <p>Copyright © 2023 Colmenadata</p>
                    </div>
                    <div className="footer-element-general footer-powered">
                        <p>Powered by Colmenadata</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
)
export default FooterLayout
