import { Button, Form, Loading } from "@carbon/react";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 } from "uuid";
import { NotificationModel } from "../../../Models/Notification.js";
import { sourcesActionsRequestThunk } from "../../../store/reducers/DAToolReducer.js";
import { pushNotification } from "../../../store/reducers/notificationsReducer.js";
import {
    arrayToCommaString,
    changeInput,
    api_form_setup, api_con_form_setup,
    formComponents,
    sanitizeItemInputs,
    toAPISource,
    validateForm
} from "../../../Utils/utils";
import APIConnection from "./APIConnect/index.js";

const Index = ({ item, inModal = false, output, parentSubmitState = undefined, setParentSubmitState = undefined }) => {
    const [keyState, setKeyState] = useState(v4());
    const [localState, setLocalState] = useState({ ...api_form_setup });
    const [itemState, setItemState] = useState({ ...item });
    const [errorState, setErrorState] = useState({ ...api_form_setup });
    const [submitState, setSubmitState] = useState(false);
    const [inputState, setInputState] = useState({ main: [] });
    const [inputItemsState, setInputItemsState] = useState({ ...recalculateInputs() });
    const [connectionState, setConnectionState] = useState({ ...api_con_form_setup });
    const dispatch = useDispatch();
    const inputRef = useRef();

    function recalculateInputs() {
        return {
            main: [{
                type: 'text',
                id: 'name',
                invalidText: errorState.name,
                labelText: 'Nombre de referencia para la API',
                placeholder: 'Introduce un nombre de referencia para la API',
                value: localState.name,
                invalid: errorState.name
            }],
        };
    }

    useEffect(() => {
        setKeyState(() => v4());
        if (itemState === '' || itemState === {}) {
            setItemState(() => ({ ...api_form_setup }));
        } else {
            let nItem = { ...item };
            if (nItem && nItem.connection) {
                nItem.connection = {...nItem.connection, ...item.connection}
            }
            setItemState((state) => ({ ...state, ...sanitizeItemInputs(nItem) }));
        }
    }, [item]);

    useEffect(() => {
        if (itemState === '' || itemState === {}) {
            setLocalState((state) => ({ ...state, ...itemState }));
        }
    }, [itemState]);

    useEffect(() => {
        if (inModal) {
            output(localState);
        }
        setInputItemsState(() => ({ ...recalculateInputs() }));
    }, [localState]);

    useEffect(() => {
        if (parentSubmitState && validateForm(localState, setErrorState, ['id', 'endpoint', 'target_path', 'data_type'])) {
            output(localState);
        } else {
            if (setParentSubmitState) {
                setParentSubmitState(() => false);
            }
        }
    }, [parentSubmitState]);

    let title = (
        <h4 className="title" aria-label={itemState.name}>
            {itemState && Object.keys(itemState).length > 0 ? 'Edición' : 'Nueva'} API <span className='item-name'>{itemState.name}</span>
        </h4>
    );

    useEffect(() => {
        setInputState((state) => ({
            ...state,
            main: formComponents(inputItemsState.main, setLocalState, setErrorState, localState, errorState, inputRef, keyState)
        }));
    }, [localState, errorState, inputItemsState]);

    useEffect(() => {
        changeInput("connection", connectionState, setLocalState, setErrorState, localState, errorState)
    }, [connectionState])


    function saveNewItem(e) {
        console.log('DATA_TYPE' + localState.connection.data_type)
        localState.connection.data_type = 'json'
        setSubmitState(() => true);
        e.preventDefault();
        if (validateForm(localState, setErrorState, ['id', 'target_path', 'endpoint', 'password', 'data_type'])) {
            const data = toAPISource(localState);
            dispatch(sourcesActionsRequestThunk({ type: 'postAPISource', data })).then((r) => {
                if (r.error) {
                    dispatch(pushNotification(new NotificationModel('Error en la actualización', `HTTP error: ${r.payload.http_status_code}, contacte con el administrador. soporte@espossible.com`, 'error').toJson()));
                    setLocalState(() => ({ ...sanitizeItemInputs(api_form_setup) }));
                    setItemState(() => ({ ...api_form_setup }));
                } else {
                    output('saved');
                }
                setSubmitState(() => false);
            }).catch().finally(() => {
                setLocalState(() => ({ ...sanitizeItemInputs(api_form_setup) }));
            });
        } else {
            setSubmitState(() => false);
        }
    }
    
    

    return (
        <>
            {localState && <div className="dbimport">
                {title}
                <div className="inner-content">
                    <Form key={keyState}>
                        <div className={'form-content'}>
                            {inputState.main}
                            <div className={'form-block'}>
                                <h4>Configuración de la api</h4>
                                <APIConnection item={itemState ? itemState.connection : ''}
                                    output={(returnData) => setConnectionState((prevState) => ({ ...prevState, ...returnData }))}
                                    saveCounter={submitState}
                                    setParentState={setLocalState}
                                    setParentErrorState={setErrorState}
                                    parentErrorState={errorState}
                                    parentState={localState} />
                            </div>
                            {!inModal && <Button
                                kind="primary"
                                className={'button-loading'}
                                onClick={(e) => saveNewItem(e)}
                                disabled={submitState}
                            >
                                Guardar
                                {submitState && <Loading
                                    description="Active loading indicator" withOverlay={false}
                                    small />}
                            </Button>}
                        </div>
                    </Form>
                </div>
            </div>}
        </>
    );
}


export default Index;
