import {getAxiosPrivate, getAxiosPublic} from "./common";

const tokenRequestURL = "/token/"
const tokenRefreshURL = "/token/refresh/"
const profileRequestURL = "/user/profile/"
const isAdminRequestURL = "/is_admin/"

export const tokenRefresh = async (refreshToken) => {
    if (refreshToken["refresh"] === null){
        return "null"
    }else{
        return getAxiosPublic()
        .post(tokenRefreshURL, refreshToken)
    }
}


export const tokenRequest = async (loginForm) => {
    return getAxiosPublic()
        .post(tokenRequestURL, loginForm)
}

export const profileRequest = async (store) => {
    return getAxiosPrivate(store)
        .get(profileRequestURL)
}
export const isAdminRequest = async (store) => {
    return getAxiosPrivate(store)
        .get(isAdminRequestURL)
}
export const profileUpdateRequest = async (store, profile) => {
    return getAxiosPrivate(store)
        .put(`${profileRequestURL}${profile.user.id}/`, profile)
}
