import {Button, InlineLoading} from "@carbon/react";

const Index = ({panel, reloadPanel, loading}) => {
    return (
        <div id={'panel_placeholder' + panel.id} className='panel-placeholder'>
            <h2>Error en la carga del panel</h2>
            <h3>{panel.name}</h3>
            <div className={'reload-holder'}>
                <Button className={'reload-button'} onClick={() => !loading ? reloadPanel(panel) : ''}>Recargar
                </Button>
                {loading !== undefined ?
                    <InlineLoading
                        status={loading ? 'active' : 'inactive'}
                        iconDescription="Active loading indicator"
                        description=""
                    /> : <div className={'reload-loading-placeholder'}></div>
                }
            </div>

        </div>
    )
}
export default Index
