import React, {useEffect, useRef, useState} from 'react';
import {v4} from "uuid";
import {formComponents, parameters, sanitizeItemInputs} from "../../../../../../Utils/utils.js";

export const explode_types = [
    { id: 'EXPLODE_REGEX', text: 'EXPLODE_REGEX' },
    { id: 'EXPLODE_AUX_COLUMN', text: 'EXPLODE_AUX_COLUMN' },
]

function Index({item, setParentErrorState, parentErrorState, output, reset, setReset}) {
    const [keyState, setKeyState] = useState(v4())
    const inputRef = useRef()
    const [inputState, setInputState] = useState([])
    const [localState, setLocalState] = useState({...parameters})
    const [conditionalInputsState, setConditionalInputsState] = useState([])
    const [conditionalInputitemsState, setConditionalInputItemsState] = useState([])
    const [inputItemsState, setInputItemsState] = useState([...recalculateInputs()])

    function recalculateInputs() {
        return [
            {
                type: 'combo',
                id: 'explode_type',
                invalidText: parentErrorState.parameters.explode_type,
                labelText: 'Tipo de explode a realizar',
                placeholder: 'Selecciona el tipo de explode que desea realizar',
                value: localState.explode_type,
                invalid: parentErrorState.parameters.explode_type,
                types: explode_types,
                parentErrorName: 'parameters'
            },
        ]
    }

    useEffect(() => {
        let localitem = {...item}
        if (!item || Object.keys(item).length === 0) {
            localitem = {...parameters}
        }
        setKeyState(() => v4())
        setLocalState((state) => ({...state, ...sanitizeItemInputs(localitem)}))
        explodetypeChooser()
    }, [item])
    useEffect(() => {
        output(localState)
        setInputItemsState(() => [...recalculateInputs()])
    }, [localState])
    useEffect(() => {
        setInputItemsState(() => [...recalculateInputs()])
    }, [parentErrorState])
    useEffect(() => {
        explodetypeChooser()
        setInputState(() => formComponents(inputItemsState, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [inputItemsState])

    useEffect(() => {
        if (reset) {
            setLocalState(() => ({...parameters}))
            setReset(() => false)
        }
    }, [reset])

    useEffect(() => {
        setConditionalInputsState(() => formComponents(conditionalInputitemsState, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [conditionalInputitemsState])

    function explodetypeChooser() {
        switch (localState.explode_type) {
            case 'EXPLODE_REGEX':
                setConditionalInputItemsState(() => [{
                    type: 'text',
                    id: 'explode_regexp',
                    invalidText: parentErrorState.parameters.explode_regexp,
                    labelText: 'Expresión regexp para hace explode de las columnas',
                    placeholder: 'Introduce la expresión regexp para el explode',
                    value: localState.explode_regexp,
                    invalid: parentErrorState.parameters.explode_regexp,
                    parentErrorName: 'parameters'
                }])
                break;
            case 'EXPLODE_AUX_COLUMN':
                setConditionalInputItemsState(() => [
                    {
                        type: 'text',
                        id: 'explode_aux_column',
                        invalidText: parentErrorState.parameters.explode_aux_column,
                        labelText: 'Columna que se utilizará para el explode',
                        placeholder: 'Introduce la columna que se utilizará para hacer el explode a las columnas de entrada',
                        value: localState.explode_aux_column,
                        invalid: parentErrorState.parameters.explode_aux_column,
                        parentErrorName: 'parameters'
                    },
                    {
                        type: 'text',
                        id: 'explode_join_key',
                        invalidText: parentErrorState.parameters.explode_join_key,
                        labelText: 'En caso de usar la columna aux para explotar, esta es la lista de columnas a usar como clave en los join left',
                        placeholder: 'Introduce la lista de columnas keys',
                        value: localState.explode_join_key,
                        invalid: parentErrorState.parameters.explode_join_key,
                        parentErrorName: 'parameters'
                    },
                    {
                        type: 'text',
                        id: 'explode_dependent_columns',
                        invalidText: parentErrorState.parameters.explode_dependent_columns,
                        labelText: 'En caso de usar la columna aux para el explode, esta es la lista de columnas que necesitan explotar, son todas las columnas que dependen de la columna aux',
                        placeholder: 'Introduce la lista de columnas a hacer el explode',
                        value: localState.explode_dependent_columns,
                        invalid: parentErrorState.parameters.explode_dependent_columns,
                        parentErrorName: 'parameters'
                    },
                ])
                break;
            default:
                setConditionalInputItemsState(() => [])
                break
        }
    }

    return (
        <div>
            {inputState}
            {conditionalInputsState}
        </div>
    );
}

export default Index;
