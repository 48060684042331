import React, { useEffect, useRef, useState } from 'react';
import { v4 } from "uuid";
import { formComponents, parameters_kdd, sanitizeItemInputs } from "../../../../../../Utils/utils.js";

export const drop_duplicate_choice = [
    { id: 'True', text: 'True' },
    { id: 'False', text: 'False' },
]

function Index({ item, setParentErrorState, parentErrorState, output, reset, setReset }) {
    const [keyState, setKeyState] = useState(v4())
    const inputRef = useRef()
    const [inputState, setInputState] = useState([])
    const [localState, setLocalState] = useState({ ...parameters_kdd })
    const [conditionalInputsState, setConditionalInputsState] = useState([])
    const [conditionalInputitemsState, setConditionalInputItemsState] = useState([])
    const [inputItemsState, setInputItemsState] = useState([...recalculateInputs()])

    function recalculateInputs() {
        return [
            {
                type: 'text',
                id: 'reduce_select_columns',
                invalidText: parentErrorState.parameters_kdd.reduce_select_columns,
                labelText: 'Inserta las columnas',
                placeholder: 'Inserta las columnas',
                value: localState.reduce_select_columns,
                invalid: parentErrorState.parameters_kdd.reduce_select_columns,
                parentErrorName: 'parameters_kdd'
            },
            {
                type: 'combo',
                id: 'reduce_drop_duplicate',
                invalidText: parentErrorState.parameters_kdd.reduce_drop_duplicate,
                labelText: '¿Eliminar duplicados?',
                placeholder: 'Selecciona si eliminar duplicados',
                value: localState.reduce_drop_duplicate,
                invalid: parentErrorState.parameters_kdd.reduce_drop_duplicate,
                types: drop_duplicate_choice,
                parentErrorName: 'parameters_kdd'
            },
        ]
    }

    useEffect(() => {
        let localitem = { ...item }
        if (!item || Object.keys(item).length === 0) {
            localitem = { ...parameters_kdd }
        }
        setKeyState(() => v4())
        setLocalState((state) => ({ ...state, ...sanitizeItemInputs(localitem) }))
        enforceChooser()
    }, [item])
    useEffect(() => {
        output(localState)
        setInputItemsState(() => [...recalculateInputs()])
    }, [localState])
    useEffect(() => {
        setInputItemsState(() => [...recalculateInputs()])
    }, [parentErrorState])
    useEffect(() => {
        setConditionalInputsState(() => [])
        enforceChooser()
        setInputState(() => formComponents(inputItemsState, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [inputItemsState])

    useEffect(() => {
        if (reset) {
            setLocalState(() => ({ ...parameters_kdd }))
            setReset(() => false)
        }
    }, [reset])

    useEffect(() => {
        setConditionalInputsState(() => formComponents(conditionalInputitemsState, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [conditionalInputitemsState])

    function enforceChooser() {
        switch (localState.enforce_type) {
            case 'EXAMPLE':
                setConditionalInputItemsState(() => [{
                    type: 'text',
                    id: 'example',
                    invalidText: parentErrorState.parameters_kdd.example,
                    labelText: 'Formato de fecha',
                    placeholder: 'Introduce el formato de fecha',
                    value: localState.example,
                    invalid: parentErrorState.parameters_kdd.example,
                    parentErrorName: 'parameters_kdd'
                }])
                break;
            default:
                setConditionalInputItemsState(() => [])
                break
        }
    }

    return (
        <div>
            {inputState}
            {conditionalInputsState}
        </div>
    );
}

export default Index;
