import { Button, Content, Loading, PaginationNav, Theme } from "@carbon/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Pipeline from "../../components/DataProcessSection/Pipeline/index.js";
import { NotificationModel } from "../../Models/Notification.js";
import { sourcesActionsRequestThunk } from "../../store/reducers/DAToolReducer.js";
import { pushNotification } from "../../store/reducers/notificationsReducer.js";
import { loadPipelines } from "../../Utils/HTTPUtils.js";

const itemsPerPage = 8

const Index = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const datoolStore = useSelector((state) => state.datool)
    const [showState, setShowState] = useState(false);
    const [pageIndexState, setPageIndexState] = useState(0);
    const [selectedPhase, setSelectedPhase] = useState({ id: 0, title: '' });
    const [currentPagePipelinesState, setCurrentPagePipelinesState] = useState([]);
    const [pipelinesState, setpipelinesState] = useState([]);
    const [defaultPipelinesState, setDefaultPipelinesState] = useState([]);

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        loadPipelines(setLoading, dispatch)
        // setLoading(() => true)
        // setDefaultPipelinesState(() => [...showPipelines()])
        // setpipelinesState(() => [...showPipelines()])
    }, [])

    useEffect(() => {
        setLoading(() => true)
        setDefaultPipelinesState(() => [...showPipelines()])
        setpipelinesState(() => [...showPipelines()])
    }, [datoolStore.pipelines])

    useEffect(() => {
        extractNextItems(0, itemsPerPage)
    }, [pipelinesState])

    const actionSelector = {
        updateItem: (returnData) => {
            navigate('/data-process/pipelines', { state: { item: returnData } })
            console.log('update pipeline', returnData)
            updateItem(returnData)

        }, deleteItem: (returnData) => {
            navigate('/data-process/pipelines', { state: { item: {} } })
            console.log('delete pipeline', returnData)
            deleteItem(returnData)

        }, createItem: (returnData) => {
            navigate('/data-process/pipelines', { state: { item: '' } })
            createItem(returnData)
        },

    }
    return (
        <Content id="data-analysis-page" className="page-container data-process">
            <section className={'section-display'}>
            </section>
            <div className={'page-title'}>
                <h2>Pipelines</h2>
                <Button onClick={() => actionSelector['createItem']()}>Nuevo</Button>
            </div>
            {loading && <div className="status-container">
                <Loading
                    description="Active loading indicator" withOverlay={false}
                />
            </div>}
            <section className={'pipelines-container'}>
                {currentPagePipelinesState}
            </section>
            {pipelinesState.length > itemsPerPage &&
                <section className={'pipelines-nav'}>
                    <Theme theme={'g100'}>
                        <PaginationNav itemsShown={itemsPerPage} page={pageIndexState} totalItems={paginationTotalItems()}
                            onChange={(next) => changePage(next)}></PaginationNav>
                    </Theme>
                </section>}
        </Content>
    )


    /**
     *  Sets selected index items
     * @param start
     * @param end
     */
    function extractNextItems(start, end) {
        setCurrentPagePipelinesState(() => [...defaultPipelinesState.slice(start, end)])
    }

    /**
     * Performs page change
     * @param next
     */
    function changePage(next) {
        setPageIndexState(() => next)
        let prev = ((next + 1) * itemsPerPage) - itemsPerPage
        extractNextItems(prev, (next + 1) * itemsPerPage)
    }

    /**
     * function that calculates how many pages are needed
     * @returns {number}
     */
    function paginationTotalItems() {
        return !!((pipelinesState.length / itemsPerPage) % 2) ? Math.floor((pipelinesState.length / itemsPerPage) + 1) : pipelinesState.length / itemsPerPage
    }

    function actionChooser(data, action) {
        actions[action](data)
    }


    function handleSelection(phase) {
        setCurrentPagePipelinesState((state) => ({ ...state, ...phase }))
    }

    function showPipelines() {
        // let tempObject = [{
        //     "id": "",
        //     "name": "asd",
        //     "source": "1@downloader",
        //     "join_steps": [
        //         {
        //             "id": "",
        //             "tempId": ":r2:",
        //             "target": "1@downloader",
        //             "order": 1,
        //             "join_type": "INNER",
        //             "table_A_key": "CC",
        //             "table_B_key": "CC",
        //             "second_source": "1@downloader",
        //         },
        //         {
        //             "id": "",
        //             "tempId": ":r2:",
        //             "target": "1@downloader",
        //             "order": 1,
        //             "join_type": "INNER",
        //             "table_A_key": "CC",
        //             "table_B_key": "CC",
        //             "second_source": "1@downloader",
        //         },
        //     ],
        //     "sanitization_steps": [
        //         {
        //             "id": "",
        //             "tempId": ":r1:",
        //             "target": "1@downloader",
        //             "target_column": "s",
        //             "sanitization_type": "CLEANNING",
        //             "order": 1,
        //             "parameters": {
        //                 "enforce_type": "",
        //                 "labels": "",
        //                 "cut_points": "",
        //                 "num_buckets": "",
        //                 "date_format": "",
        //                 "replacement_type": "",
        //                 "index_column": "",
        //                 "literal_value": "",
        //                 "data_type": "",
        //                 "literal_data_type": "",
        //                 "interpolation_index_column": "",
        //                 "remove_outliers_groups": "",
        //                 "is_integer": "",
        //                 "comparison_type_replace": "",
        //                 "value_to_compare": "",
        //                 "filter_type": "",
        //                 "filter_data_type": "",
        //                 "explode_regexp": "",
        //                 "aux_column": "",
        //                 "explode_join_key": "",
        //                 "explode_dependent_columns": "",
        //                 "categorical_type": ""
        //             }
        //         }
        //     ],
        //     "kdd_steps": [
        //         {
        //             "id": "",
        //             "tempId": ":r1:",
        //             "target": "1@downloader",
        //             "target_column": "s",
        //             "sanitization_type": "CLEANNING",
        //             "order": 1,
        //             "parameters": {
        //                 "enforce_type": "",
        //                 "labels": "",
        //                 "cut_points": "",
        //                 "num_buckets": "",
        //                 "date_format": "",
        //                 "replacement_type": "",
        //                 "index_column": "",
        //                 "literal_value": "",
        //                 "data_type": "",
        //                 "literal_data_type": "",
        //                 "interpolation_index_column": "",
        //                 "remove_outliers_groups": "",
        //                 "is_integer": "",
        //                 "comparison_type_replace": "",
        //                 "value_to_compare": "",
        //                 "filter_type": "",
        //                 "filter_data_type": "",
        //                 "explode_regexp": "",
        //                 "aux_column": "",
        //                 "explode_join_key": "",
        //                 "explode_dependent_columns": "",
        //                 "categorical_type": ""
        //             }
        //         },
        //         {
        //             "id": "",
        //             "tempId": ":r1:",
        //             "target": "1@downloader",
        //             "target_column": "s",
        //             "sanitization_type": "CLEANNING",
        //             "order": 1,
        //             "parameters": {
        //                 "enforce_type": "",
        //                 "labels": "",
        //                 "cut_points": "",
        //                 "num_buckets": "",
        //                 "date_format": "",
        //                 "replacement_type": "",
        //                 "index_column": "",
        //                 "literal_value": "",
        //                 "data_type": "",
        //                 "literal_data_type": "",
        //                 "interpolation_index_column": "",
        //                 "remove_outliers_groups": "",
        //                 "is_integer": "",
        //                 "comparison_type_replace": "",
        //                 "value_to_compare": "",
        //                 "filter_type": "",
        //                 "filter_data_type": "",
        //                 "explode_regexp": "",
        //                 "aux_column": "",
        //                 "explode_join_key": "",
        //                 "explode_dependent_columns": "",
        //                 "categorical_type": ""
        //             }
        //         }
        //     ],
        //     "periodicity": {
        //         "start_date": "14/11/2022T00:00",
        //         "interval": "@once"
        //     }
        // }]
        let pipelines = []
        if (datoolStore.pipelines.length > 0) {
            pipelines = datoolStore.pipelines.map((p) => {
                // pipelines = tempObject.map((p) => {
                return <Pipeline key={p.name} data={p} title={'#' + p.name}
                    output={(returnData) => actionSelector['updateItem'](returnData)}></Pipeline>
            })
        }
        return pipelines
    }

    /**
     * Loads sources data into store
     */
    // function loadDataSources() {
    //     dispatch(sourcesActionsRequestThunk({ type: 'getSources', id: '' })).then()
    //         .catch((error) => {
    //             dispatch(pushNotification(new NotificationModel('Error en la carga',
    //                 `HTTP error: ${error.http_status_code}, contacte con el administrador. soporte@espossible.com`,
    //                 'error').toJson()))
    //         }).finally(setLoading(() => false))
    // }

    function updateItem(data) {
        console.log('Edit', data)
        // loadDataSources()
    }

    function deleteItem(data) {
        console.log('Delete', data)
        // loadDataSources()
    }

    function createItem(data) {
        console.log('New', data)
        // loadDataSources()
    }

    /**
     * Function that returns sources with their id updated with id+type
     * @param sources
     * @returns {Object[]}
     */
    function uniqueIdForTable(sources) {
        let processed_sources = [...sources]
        processed_sources.forEach((e) => {
            e.id = `${e.id}@${e.global_type}`
        })
        return processed_sources
    }
}
const actions = {
    addPhase: (data) => {
        console.log(data);
    }
}
export default Index
