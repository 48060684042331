import { useEffect, useRef, useState } from "react";
import { v4 } from "uuid";
import { api_con_form_setup, formComponents } from "../../../../Utils/utils";

const data_types = [{key: 'JSON', name: 'json'}, {key: 'CSV', name: 'csv'}]

const Index = ({ item, output, parentErrorState, setParentErrorState, saveCounter }) => {
    const [localState, setLocalState] = useState({ ...api_con_form_setup });
    const [keyState, setKeyState] = useState(v4());
    const [inputState, setInputState] = useState([]);
    const inputRef = useRef();

    useEffect(() => {
        if (saveCounter) {
            let localitem = { ...item };
            localitem = { ...reset() };
            setKeyState(() => v4());
            setLocalState((state) => ({ ...state, ...localitem }));
        }
    }, [saveCounter]);

    useEffect(() => {
        let localitem = { ...item };
        if (!item || Object.keys(item).length === 0) {
            localitem = reset();
        }
        setKeyState(() => v4());
        setLocalState((state) => ({ ...state, ...localitem }));
    }, [item]);

    useEffect(() => {
        output(localState)
        setInputState(() => formComponents(inputs, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [localState])

    useEffect(() => {
        setInputState(() => formComponents(inputs, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState));
    }, [localState.connection]);

    useEffect(() => {
        setInputState(() => formComponents(inputs, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState));
    }, [parentErrorState]);

    function reset() {
        let localItem = { ...api_con_form_setup };
        return localItem;
    }

    const inputs = [
        {
            type: 'group',
            inner: [
                {
                    type: 'text',
                    id: 'target_path',
                    invalidText: parentErrorState.connection.target_path,
                    labelText: 'Nombre para la base de datos',
                    placeholder: 'Introduce un nombre para la base de datos',
                    value: localState.target_path,
                    invalid: parentErrorState.connection.target_path,
                    parentErrorName: 'connection',
                },
            ]
        },
        {
            type: 'group',
            inner: [
                {
                    type: 'text',
                    id: 'endpoint',
                    invalidText: parentErrorState.connection.endpoint,
                    labelText: 'Endpoint de la API',
                    placeholder: 'Introduce un nombre para el endpoint de la API',
                    value: localState.endpoint,
                    invalid: parentErrorState.connection.endpoint,
                    parentErrorName: 'connection',
                }
            ]
        },
        {
            type: 'group',
            inner: [
                {
                    type: 'text',
                    subType: 'password',
                    id: 'password',
                    invalidText: parentErrorState.connection.password,
                    labelText: 'Contraseña para la conexión con la API',
                    placeholder: 'Introduce una contraseña para la conexión con la API',
                    value: localState.password,
                    invalid: parentErrorState.connection.password,
                    parentErrorName: 'connection',
                },
            ]
        },
        {
            type: 'group',
            inner: [
                {
                    type: 'select',
                    id: 'data_type',
                    invalidText: parentErrorState.connection.data_type,
                    labelText: 'Tipo de dato',
                    placeholder: 'Selecciona un tipo de dato',
                    value: localState.data_type,
                    invalid: parentErrorState.connection.data_type,
                    parentErrorName: 'connection',
                    types: data_types
                },
            ]
        },
    ];

    return (
        <div className="apicon">
            <div className="inner-content">
                <div className={'form-content'}>
                    {inputState}
                </div>
            </div>
        </div>
    );
}

export default Index;
