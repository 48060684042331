import React, {useEffect, useRef, useState} from 'react';
import {v4} from "uuid";
import {arrayToCommaString, formComponents, parameters, sanitizeItemInputs} from "../../../../../../../Utils/utils.js";

const enforce_types = [
    {key: 'NUM BUCKETS', name: 'NUM BUCKETS'},
    {key: 'CUT POINTS', name: 'CUT POINTS'},
]

function Index({item, setParentErrorState, parentErrorState, output, reset, setReset, enforceType}) {
    const [keyState, setKeyState] = useState(v4())
    const inputRef = useRef()
    const [inputState, setInputState] = useState([])
    const [localState, setLocalState] = useState({...parameters})
    const [conditionalInputsState, setConditionalInputsState] = useState([])
    const [conditionalInputitemsState, setConditionalInputItemsState] = useState([])
    const [inputItemsState, setInputItemsState] = useState([...recalculateInputs()])

    function recalculateInputs() {
        return [{
            type: 'select',
            id: 'categorical_type',
            invalidText: parentErrorState.parameters.categorical_type,
            labelText: 'Tipo de categoria de enforcement',
            placeholder: 'Selecciona el tipo de categoria con la que desea forzar',
            value: localState.categorical_type,
            invalid: parentErrorState.parameters.categorical_type,
            types: enforce_types,
            parentErrorName: 'parameters'
        }]
    }

    useEffect(() => {
        let localitem = {...item}
        if (!item || Object.keys(item).length === 0) {
            localitem = {...parameters}
            localitem.categorical_type = 'NUM BUCKETS'
            localitem.enforce_type = enforceType
        }
        setKeyState(() => v4())
        setLocalState((state) => ({...state, ...sanitizeItemInputs(localitem)}))
        enforceChooser()
    }, [item])
    useEffect(() => {
        const localItem = {...localState}
        if (localState.categorical_type === 'CUT POINTS') {
            localItem.num_buckets = 0
        } else if (localState.categorical_type === 'NUM BUCKETS') {
            localItem.cut_points = 0
            localItem.labels = ''
        } else {
            localItem.num_buckets = 0
            localItem.cut_points = 0
            localItem.labels = ''
        }
        output(localItem)
        setInputItemsState(() => [...recalculateInputs()])
    }, [localState])
    useEffect(() => {
        setInputItemsState(() => [...recalculateInputs()])
    }, [parentErrorState])
    useEffect(() => {
        enforceChooser()
        setInputState(() => formComponents(inputItemsState, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [inputItemsState])

    useEffect(() => {
        if (reset) {
            setLocalState(() => ({...parameters}))
            setReset(() => false)
        }
    }, [reset])

    useEffect(() => {
        setConditionalInputsState(() => formComponents(conditionalInputitemsState, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [conditionalInputitemsState])

    function enforceChooser() {
        switch (localState.categorical_type) {
            case 'CUT POINTS':
                setConditionalInputItemsState(() => [
                    {
                        type: 'text',
                        subType: 'number',
                        id: 'cut_points',
                        invalidText: parentErrorState.parameters.cut_points,
                        labelText: 'Puntos de corte',
                        placeholder: 'Introduce los puntos de corte separados por comas',
                        value: arrayToCommaString(localState.cut_points),
                        invalid: parentErrorState.parameters.cut_points,
                        parentErrorName: 'parameters'
                    },
                    {
                        type: 'text',
                        id: 'labels',
                        invalidText: parentErrorState.parameters.labels,
                        labelText: 'Nombres para los puntos de corte',
                        placeholder: 'Introduce los nombres, para los puntos de corte, separados por comas',
                        value: arrayToCommaString(localState.labels),
                        invalid: parentErrorState.parameters.labels,
                        parentErrorName: 'parameters'
                    }
                ])
                break;
            default:
                setConditionalInputItemsState(() => [{
                    type: 'text',
                    subType: 'number',
                    id: 'num_buckets',
                    invalidText: parentErrorState.parameters.num_buckets,
                    labelText: 'Numero de buckets',
                    placeholder: 'Introduce la cantidad e buckets necesarios',
                    value: localState.num_buckets,
                    invalid: parentErrorState.parameters.num_buckets,
                    parentErrorName: 'parameters'
                }])
                break;
        }
    }

    return (
        <div>
            {inputState}
            {conditionalInputsState}
        </div>
    );
}

export default Index;
