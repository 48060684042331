import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { actionSourcesRequest } from "../../apiClient/DAToolRequests.js";


export const sourcesActionsRequestThunk = createAsyncThunk(
    'sources',
    async (opt, thunkAPI) => {
        try {
            let res = await actionSourcesRequest(thunkAPI, opt.type, opt.id, opt.data);
            return thunkAPI.fulfillWithValue(res.data)
        } catch (err) {
            // hidrate error information on failed request
            return thunkAPI.rejectWithValue({
                http_status_code: err.response?.status
            })
        }
    }
)

export const pipelinesActionsRequestThunk = createAsyncThunk(
    'pipelines',
    async (opt, thunkAPI) => {
        try {
            let res = await actionSourcesRequest(thunkAPI, opt.type, opt.id, opt.data);
            return thunkAPI.fulfillWithValue(res.data)
        } catch (err) {
            console.error(err)
            // hidrate error information on failed request
            return thunkAPI.rejectWithValue({
                http_status_code: err.response?.status
            })
        }
    }
)

export const datoolReducer = createSlice({
    name: 'datool',
    initialState: {
        sources: {},
        pipelines: []
    },
    reducers: {
        // pushPipeline: (state, action) => {
        //     state.pipelines = [...state.pipelines, action.payload]
        // },
    },
    extraReducers: (builder) => {
        builder.addCase(sourcesActionsRequestThunk.pending, (state, action) => {
        })
        builder.addCase(sourcesActionsRequestThunk.fulfilled, (state, action) => {
            state.sources = { ...action.payload }
        })
        builder.addCase(sourcesActionsRequestThunk.rejected, (state) => {
        })
        builder.addCase(pipelinesActionsRequestThunk.fulfilled, (state, action) => {
            state.pipelines = action.payload
        })
    }
})

export const { pushPipeline } = datoolReducer.actions
// Action creators are generated for each case reducer function
export default datoolReducer.reducer
