import { coolGray, cyan, gray, green, magenta, orange, purple, red, teal, warmGray } from '@carbon/colors';
import { ArrowRight } from "@carbon/icons-react";
import { Popover, PopoverContent } from "@carbon/react";
import { useState } from "react";
import { generatePhaseDescription } from "../../../../../Utils/utils.js";

const Index = ({ phase, index, last, phaseSelect, changeOrder, showTooltip = true }) => {
    const [tooltipState, setTooltipState] = useState(false)
    const title = () => {
        if (Object.keys(phase).includes('type_join')) {
            return phase.title ? phase.title : phase.type_join.charAt(0).toUpperCase()
        }
        if (Object.keys(phase).includes('parameters')) {
            if (phase.parameters.sanitization_type)
                return phase.title ? phase.title : phase.parameters.sanitization_type.charAt(0).toUpperCase()
        }
        // TODO: Process phase title
        if (Object.keys(phase).includes('process_type')) {
            return phase.title ? phase.title : phase.process_type.charAt(0).toUpperCase()
        }
    }
    return (
        <div className={'phase-container'} onDrop={(e) => drop(e)}
            onDragOver={(e) => allowDrop(e)}>


            <Popover open={showTooltip && tooltipState} autoAlign={true} align={'right'}>
                <div className={'phase cds--btn'} draggable={"true"} onDragStart={(e) => drag(e)}
                    style={colorChooser(index)}
                    onClick={() => phaseSelect(phase)} onMouseOver={() => setTooltipState(true)}
                    onMouseLeave={() => setTooltipState(false)}>
                    {title()}
                    <PopoverContent id={`phase#${index}`}>
                        {generatePhaseDescription(phase, ['id', 'tempId'])}
                    </PopoverContent>
                </div>
            </Popover>
            {last - 1 !== index &&
                <div className={'phase-separator'}><ArrowRight size={24}></ArrowRight></div>
            }
        </div>)

    function colorChooser(index) {
        let ind = 0;
        if (index < 10) {
            ind = (index + 1) * 10
            return buildColor(ind, colorPalette[1])
        }
        if (index > 9) {
            ind = (Number(index.toString().split('')[index.toString().length - 1]) + 1) * 10
            let dec = Number(index.toString().split('')[0])
            let colorIndx = ind
            let module = dec % 2
            // colorIndx = module ? 110 - ind : ind
            return buildColor(colorIndx, colorPalette[1])
        }
    }

    function drop(e) {
        togglePhasePopover()
        e.preventDefault();
        let data = e.dataTransfer.getData("text");
        changeOrder({ oldPositionItem: JSON.parse(data), newPositionItem: phase })
    }

    function drag(e) {
        togglePhasePopover()
        e.dataTransfer.setData("text", JSON.stringify(phase));
    }

    function allowDrop(e) {
        e.preventDefault();
    }

    function buildColor(ind, color) {
        return { backgroundColor: color[ind].toString(), color: textColor(ind) }
    }

    function textColor(index) {
        return index < 60 ? 'black' : 'white'
    }

    function togglePhasePopover() {
        setTooltipState(!tooltipState)
    }
}
const colorPalette = {
    1: orange,
    2: purple,
    3: cyan,
    4: teal,
    5: green,
    6: coolGray,
    7: gray,
    8: warmGray,
    9: red,
    10: magenta,
}

export default Index
