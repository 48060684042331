import React, {useEffect, useRef, useState} from 'react';
import {v4} from "uuid";
import {formComponents, parameters, sanitizeItemInputs} from "../../../../../../Utils/utils.js";
import Literal from "./Literal/index.js";

const replacement_types = [
    {id: 'MEAN', text: 'MEAN'},
    {id: 'MEDIAN', text: 'MEDIAN'},
    {id: 'INTERPOLATE', text: 'INTERPOLATE'},
    {id: 'DROP', text: 'DROP'},
    {id: 'MAX', text: 'MAX'},
    {id: 'MIN', text: 'MIN'},
    {id: 'LITERAL', text: 'LITERAL'},
]

function Index({item, setParentErrorState, parentErrorState, output, reset, setReset}) {
    const [keyState, setKeyState] = useState(v4())
    const inputRef = useRef()
    const [inputState, setInputState] = useState([])
    const [localState, setLocalState] = useState({...parameters})
    const [conditionalInputsState, setConditionalInputsState] = useState([])
    const [conditionalInputitemsState, setConditionalInputItemsState] = useState([])
    const [inputItemsState, setInputItemsState] = useState([...recalculateInputs()])
    const [componentInputState, setComponentInputState] = useState([])

    function recalculateInputs() {
        return [{
            type: 'combo',
            id: 'replacement_type',
            invalidText: parentErrorState.parameters.replacement_type,
            labelText: 'Tipo de reemplazo',
            placeholder: 'Selecciona el tipo de reemplazo',
            value: localState.replacement_type,
            invalid: parentErrorState.parameters.replacement_type,
            types: replacement_types,
            parentErrorName: 'parameters'
        }]
    }

    useEffect(() => {
        let localitem = {...item}
        if (!item || Object.keys(item).length === 0) {
            localitem = {...parameters}
        }
        setKeyState(() => v4())
        setLocalState((state) => ({...state, ...sanitizeItemInputs(localitem)}))
        enforceChooser()
    }, [item])
    useEffect(() => {
        output(localState)
        setInputItemsState(() => [...recalculateInputs()])
    }, [localState])
    useEffect(() => {
        setInputItemsState(() => [...recalculateInputs()])
    }, [parentErrorState])
    useEffect(() => {
        enforceChooser()
        setInputState(() => formComponents(inputItemsState, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [inputItemsState])

    useEffect(() => {
        if (reset) {
            setLocalState(() => ({...parameters}))
            setReset(() => false)
        }
    }, [reset])

    useEffect(() => {
        setConditionalInputsState(() => formComponents(conditionalInputitemsState, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [conditionalInputitemsState])

    function enforceChooser() {
        switch (localState.replacement_type) {
            case 'INTERPOLATE':
                setConditionalInputItemsState(() => [{
                    type: 'text',
                    subType: 'number',
                    id: 'index_column',
                    invalidText: parentErrorState.parameters.index_column,
                    labelText: 'Índice de columnas',
                    placeholder: 'Introduce el índice de columnas',
                    value: localState.index_column,
                    invalid: parentErrorState.parameters.index_column,
                    parentErrorName: 'parameters'
                }])
                break;
            case 'LITERAL':
                setComponentInputState(() => formChooser())
                setConditionalInputItemsState(() => [{
                    type: 'text',
                    subType: 'number',
                    id: 'literal_NA',
                    invalidText: parentErrorState.parameters.literal_NA,
                    labelText: 'Valor literal de reemplazamiento',
                    placeholder: 'Introduce el valor literal de reemplazamiento',
                    value: localState.literal_NA,
                    invalid: parentErrorState.parameters.literal_NA,
                    parentErrorName: 'parameters'
                }])
                break;
            default:
                setConditionalInputItemsState(() => [])
                setComponentInputState(() => [])
                break
        }
    }

    const formTypes = {
        'LITERAL': <Literal item={item ? item : ''}
                            type={'LITERAL'}
                            output={(returnData) => setLocalState((state) => ({...state, ...returnData}))}
                            setParentErrorState={setParentErrorState}
                            parentErrorState={parentErrorState}
                            reset={reset} setReset={setReset}></Literal>,
    }

    function formChooser() {
        return formTypes[localState.replacement_type]
    }

    return (
        <div>
            {inputState}
            {conditionalInputsState}
            {componentInputState}
        </div>
    );
}

export default Index;
