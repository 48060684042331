import {
    Checkbox,
    DatePicker,
    DatePickerInput,
    InlineNotification,
    NotificationActionButton,
    Select,
    SelectItem,
    TimePicker
} from "@carbon/react";
import {useEffect, useState} from "react";
import {v4} from "uuid";
import {backendToDatePickerString, dataSources, toDatePickerString} from "../../Utils/utils.js";

const days = [{name: 'L', key: 'L', state: false, id: 1}, {name: 'M', key: 'M', state: false, id: 2},
    {name: 'M', key: 'MI', state: false, id: 3}, {name: 'J', key: 'J', state: false, id: 4},
    {name: 'V', key: 'V', state: false, id: 5}, {name: 'S', key: 'S', state: false, id: 6}, {
        name: 'D',
        key: 'D',
        state: false,
        id: 7
    }]
const cronDefaults = [
    {key: '@once', name: 'Una vez'},
    {key: '@hourly', name: 'Cada hora'},
    {key: '@daily', name: 'Diario'},
    {key: '@weekly', name: 'Semanal'},
    {key: '@monthly', name: 'Mensual'},
    {key: '@yearly', name: 'Anual'},
]
const Index = ({item, output, setParentState, setParentErrorState, saveCounter}) => {
    let key = v4()
    key = item.id ? item.id : key
    const [disableCronState, setDisableCronState] = useState(false)
    const [crondaysState, setCronDaysState] = useState([...days])
    const [selectionState, setSelectionState] = useState('@once')
    const [itemState, setItemState] = useState({...dataSources})
    const [errorState, setErrorState] = useState({...dataSources})
    const [dateState, setDateState] = useState('')
    const [timeState, setTimeState] = useState('00:00')
    const [cronState, setCronState] = useState({minute: '0', hour: '0', month_day: '*', month: '*', week_day: '*'})
    const [acumulationState, setAcumulationState] = useState(false)
    useEffect(() => {
        let localitem = {...item}
        if (Object.keys(item).length === 0) {
            localitem = {...resetCronPicker()}
        }
        setItemState((state) => ({...state, ...localitem}))
    }, [item])
    useEffect(() => {
        if (saveCounter) {
            let localitem = {...item}
            if (Object.keys(item).length === 0) {
                localitem = {...localitem, ...resetCronPicker()}
            }
            setItemState((state) => ({...state, ...localitem}))
        }
    }, [saveCounter])
    useEffect(() => {
        if (Object.keys(itemState).length > 0) {
            extractDateTime()
            let val = '@once'
            if (itemState.periodicity.interval !== '') {
                if (!itemState.periodicity.interval.includes('*')) {
                    for (const cronDefault of cronDefaults) {
                        if (itemState.periodicity.interval === cronDefault.key) {
                            val = cronDefault.key
                            break
                        }
                    }
                } else {
                    setDisableCronState(() => true)
                    cronToLocal(itemState.periodicity.interval)
                }
            }
            setSelectionState(() => val)
            output('periodicity', {
                start_date: createDateTime(),
                interval: cronToString()
            }, setParentState, setParentErrorState)
        }
    }, [itemState])
    useEffect(() => {
        generateCron()
    }, [crondaysState])

    useEffect(() => {
        output('periodicity', {
            start_date: createDateTime(),
            interval: cronToString()

        }, setParentState, setParentErrorState)
    }, [dateState, timeState, cronState, selectionState])

    // useEffect to handle acumulationState changes
    useEffect(() => {
        const localitem = dataSources
        localitem.acumulation = acumulationState
    }, [acumulationState]);


    function resetCronPicker() {
        const localitem = dataSources
        localitem.periodicity.start_date = toDatePickerString(new Date())
        localitem.periodicity.interval = '@once'
        setTimeState(() => '00:00')
        setDisableCronState(() => false)
        return localitem
    }

    // Handler for acumulation state change
    const handleAcumulationChange = () => {
        setAcumulationState(prevState => !prevState);
    };

    const dayItems = days.map((d) => <div className={findDay(d.key).state ? 'day-box selected' : 'day-box'} key={d.key}
                                          onClick={() => selectDay(d)}>{d.name}</div>)
    return (
        <div className='cronPicker'>
            <h5>Periocidad de la descarga</h5>
            <div className='date-time-picker'>
                <DatePicker
                    className='date-picker'
                    datePickerType="single"
                    size="lg"
                    locale='es'
                    dateFormat="d/m/Y"
                    minDate={dateState}
                    id={"start_date#" + key}
                    onChange={(event) => setDateState(() => toDatePickerString(event[0]))}
                    value={dateState}
                >
                    <DatePickerInput
                        id={"start_date_input#" + key}
                        labelText="Fecha y hora (24H) de inicio de la descarga"
                        placeholder="dd/mm/aaaa"
                        size="lg"
                    />
                </DatePicker>
                <TimePicker type='time' className='time-picker' size={'lg'} id={"time-picker#" + key}
                            value={timeState}
                            onChange={(event) => setTimeState(() => event.target.value)}>
                </TimePicker>
            </div>
            <Select
                labelText="Seleccione un valor predefinido para el intervalo"
                id={"select-1#" + key}
                size="lg"
                onChange={(e) => setSelectionState(e.target.value)}
                disabled={disableCronState}
                value={selectionState}
            >
                {cronDefaults.map((cron) => {
                    return <SelectItem key={cron.key}
                                       text={`${cron.name.toUpperCase()}`}
                                       value={cron.key}
                    />
                })}
            </Select>
            <h6>Configure su propio intervalo</h6>
            <Checkbox labelText={'Repetir los días seleccionados'} id={"repeat-checkbox#" + key}
                      checked={disableCronState}
                      onChange={() => disableCronDays()}/>
            <div className={disableCronState ? 'cron' : 'disabled cron'}>
                {errorState.interval && disableCronState && <InlineNotification
                    kind="info"
                    actions={<NotificationActionButton hideCloseButton={false}
                                                       lowContrast={false}>Action</NotificationActionButton>}
                    subtitle=''
                    title="Seleccione al menos una periodicidad"
                />}
                <div className={'cron-days'}>
                    {dayItems}</div>
            <h6>Acumulatición de los datos</h6>
            <Checkbox labelText={'Sobrescritura o acumulativo'} id={"Acumulation-checkbox#" + key}
                      checked={acumulationState}
                      onChange={() => handleAcumulationChange()}/>
            </div>
        </div>
    )

    function extractDateTime() {
        if (itemState.periodicity.start_date !== '') {
            let time = timeState
            let date = itemState.periodicity.start_date
            if (itemState.periodicity.start_date.includes('T')) {
                date = backendToDatePickerString(itemState.periodicity.start_date.split('T')[0])
                time = itemState.periodicity.start_date.split('T')[1].split('Z')[0].split(':')
                time = time[0] + ':' + time[1]
            } else {
                time = '00:00'
            }
            setTimeState(() => time)
            setDateState(() => date)
        } else {
            setDateState(() => toDatePickerString(new Date()))
            setTimeState(() => '00:00')
        }
    }

    function createDateTime() {
        return toDatePickerString(dateState) + ' ' + timeState
    }

    function selectDay(date) {
        if (disableCronState) {
            const newD = crondaysState.map((d) => {
                let a = {...d}
                if (d.key === date.key) {
                    a.state = !a.state
                    return a
                }
                return a
            })
            setCronDaysState((state) => [...newD])
        }
    }

    function cronToLocal(cron) {
        const newD = crondaysState.map((d) => {
            let a = {...d}
            if (cron.includes(d.id)) {
                a.state = true
                return a
            }
            return a
        })
        setCronDaysState((state) => [...newD])
    }

    function generateCron() {
        const cron = {minute: '0', hour: '0', month_day: '*', month: '*', week_day: '*'}
        if (crondaysState.some((m) => m.state)) {
            cron.week_day = ''
        }
        for (const [index, d] of crondaysState.entries()) {
            cron.week_day += d.state ? ',' + (index + 1) : '';
        }
        cron.week_day = cron.week_day[0] === ',' ? cron.week_day.slice(1) : cron.week_day
        setCronState((state) => ({...state, ...cron}))
        setErrorState((state) => ({
            ...state, ...{
                interval: false
            }
        }))
    }

    function cronToString() {
        let cron = ''
        if (disableCronState) {
            for (let cronStateKey in cronState) {
                cron += cronState[cronStateKey] + ' '
            }
        } else {
            cron = selectionState
        }
        return cron
    }

    function disableCronDays() {
        setDisableCronState((state) => !state)
        const newD = crondaysState.map((d) => {
            let a = {...d}
            a.state = false
            return a
        })
        setCronDaysState((state) => [...newD])
    }

    function findDay(key) {
        return crondaysState.find((d) => d.key === key)
    }
}


export default Index
