import { Add, Subtract } from "@carbon/icons-react";
import { Button, Layer } from "@carbon/react";
import { useEffect, useState } from "react";
import { actions, changeOrder } from "../../../../Utils/utils.js";
import AdditionalInformationToggleTip from "../../AditionalInformationToggleTip/index.js";
import SwimLane from "../SwimLane/index.js";
import SanitizePhase from "./SanitizePhase/index.js";

const sanitize_types_info = [
    'Limpieza de columnas',
    'Transformación a tipos de datos específicos',
    'Sanitización de campos no numéricos',
    'División de campos',
    'Creación de outliers',
    'Reemplazo de valores',
    'Filtrado',
]
const Index = ({ item, inModal = false, output, reset, setReset, parentTargets }) => {
    const [localState, setLocalState] = useState([])
    const [selectedPhase, setSelectedPhase] = useState('')
    const [itemState, setItemState] = useState([...item])
    const [phaseState, setPhaseState] = useState(false)
    useEffect(() => {
        if (!phaseState) {
            setSelectedPhase(() => '')
        }
    }, [phaseState])
    useEffect(() => {
        if (!item || item === '') {
            setItemState(() => ([]))
        } else {
            setItemState(() => ([...item]))
        }
    }, [item])
    useEffect(() => {
        if (itemState !== '' || itemState !== {}) {
            setLocalState(() => ([...itemState]))
        }
    }, [itemState])

    useEffect(() => {
        output({ action: 'updateSanitize', data: localState })
    }, [localState])

    useEffect(() => {
        if (typeof reset === 'object') {
            setLocalState(() => [])
        }
        setPhaseState(() => false)
        setReset(() => false)
    }, [reset])
    return (
        <Layer>
            <div className="sanitize">
                <div className={'description'}>
                    <p>Añada fases para realizar diferentes tipos de sanitización</p>
                    <AdditionalInformationToggleTip
                        info={sanitize_types_info}></AdditionalInformationToggleTip>
                    <br />
                </div>
                <div className="inner-content">
                    <div className={'form-content'}>
                        {!inModal &&
                            <div className={'button-group'}>
                                <Button type={'button'} kind={'secondary'} renderIcon={!phaseState ? Add : Subtract}
                                    iconDescription={'Añadir fase'}
                                    onClick={() => setPhaseState(() => !phaseState)}>Añadir
                                    fase</Button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {phaseState &&
                <Layer>
                    <SanitizePhase item={selectedPhase}
                        output={(data, action) => sanitizePhaseActionChooser(data, action)}
                        inModal={false}
                        reset={reset} setReset={setReset}
                        itemCount={localState.length}
                        parentTargets={parentTargets}
                    ></SanitizePhase>
                </Layer>
            }
            <div className={'swim-line-container'}>
                <Layer>
                    <SwimLane phases={localState} title={''}
                        setSelection={(phase) => {
                            setSelectedPhase(() => ({ ...phase }))
                            setPhaseState(true)
                        }}
                        changeOrder={(returnData) => changeOrder(returnData, setLocalState)}
                    />
                </Layer>
            </div>
        </Layer>
    )

    function sanitizePhaseActionChooser(data, action) {
        actions[action](data, localState, setLocalState, setPhaseState)
        setPhaseState(() => false)
    }
}


export default Index
