
import Notification from "../../components/Notification";
import {useSelector} from "react-redux";
import {FaceCool} from "@carbon/icons-react";
import {Heading} from "@carbon/react";

const Index = () => {
    const notifications = useSelector((state) => state.notifications)
    return (
        <div className="page-container">
            <Heading>Notificaciones</Heading>
            <div id="notifications-container" className="notifications-page-container">
                {notifications.notifications.map((notification) => <Notification key={notification.id}
                                                                                 notification={notification}
                ></Notification>)
                }
                {notifications.notifications.length === 0 &&
                    <div className="no-notifications">
                        <h2>Ninguna novedad</h2>
                        <FaceCool size={32}/>
                    </div>
                }
            </div>
        </div>
    )


}

export default Index
