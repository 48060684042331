import React, {useEffect, useRef, useState} from 'react';
import {v4} from "uuid";
import {formComponents, parameters, sanitizeItemInputs} from "../../../../../../Utils/utils.js";

const replacement_types = [
    {id: 'MEAN', text: 'MEAN'},
    {id: 'MEDIAN', text: 'MEDIAN'},
    {id: 'INTERPOLATE', text: 'INTERPOLATE'},
    {id: 'DROP', text: 'DROP'},
    {id: 'MAX', text: 'MAX'},
    {id: 'MIN', text: 'MIN'},
]

const comparison_types = [
    {key: 'LTE_VALUE', name: 'LTE_VALUE'},
    {key: 'GTE_VALUE', name: 'GTE_VALUE'},
    {key: 'IT_VALUE', name: 'IT_VALUE'},
    {key: 'GT_VALUE', name: 'GT_VALUE'},
    {key: 'EQ_VALUE', name: 'EQ_VALUE'},
]

function Index({item, setParentErrorState, parentErrorState, output, reset, setReset}) {
    const [keyState, setKeyState] = useState(v4())
    const inputRef = useRef()
    const [inputState, setInputState] = useState([])
    const [localState, setLocalState] = useState({...parameters})
    const [conditionalInputsState, setConditionalInputsState] = useState([])
    const [conditionalInputitemsState, setConditionalInputItemsState] = useState([])
    const [inputItemsState, setInputItemsState] = useState([...recalculateInputs()])

    function recalculateInputs() {
        return [
            {
                type: 'select',
                id: 'is_integer',
                invalidText: parentErrorState.parameters.is_integer,
                labelText: '¿Es del tipo integer?',
                value: localState.is_integer,
                invalid: parentErrorState.parameters.is_integer,
                types: [{key: 'TRUE', name: 'TRUE'}, {key: 'FALSE', name: 'FALSE'}, {key: 'UNKNOWN', name: 'UNKNOWN'}],
                parentErrorName: 'parameters'
            },
            {
                type: 'select',
                id: 'comparison_type_replace',
                invalidText: parentErrorState.parameters.comparison_type_replace,
                labelText: 'El tipo de comparación',
                value: localState.comparison_type_replace,
                invalid: parentErrorState.parameters.comparison_type_replace,
                types: comparison_types,
                parentErrorName: 'parameters'
            },
            {
                type: 'text',
                id: 'value_to_compare',
                subType: 'number',
                invalidText: parentErrorState.parameters.value_to_compare,
                labelText: 'Valor con el que comparar',
                placeholder: 'Introduce el valor con el que comparar',
                value: localState.value_to_compare,
                invalid: parentErrorState.parameters.value_to_compare,
                parentErrorName: 'parameters'
            },
            {
                type: 'text',
                subType: 'number',
                id: 'literal_replace',
                invalidText: parentErrorState.parameters.literal_replace,
                labelText: 'Valor literal de reemplazamiento',
                placeholder: 'Introduce el valor literal de reemplazamiento',
                value: localState.literal_replace,
                invalid: parentErrorState.parameters.literal_replace,
                parentErrorName: 'parameters'
            },
            {
                type: 'combo',
                id: 'replacement_type',
                invalidText: parentErrorState.parameters.replacement_type,
                labelText: 'Tipo de reemplazo',
                placeholder: 'Selecciona el tipo de reemplazo',
                value: localState.replacement_type,
                invalid: parentErrorState.parameters.replacement_type,
                types: replacement_types,
                parentErrorName: 'parameters'
            },
        ]
    }

    useEffect(() => {
        let localitem = {...item}
        if (!item || Object.keys(item).length === 0) {
            localitem = {...parameters}
            localitem.is_integer = "TRUE"
            localitem.comparison_type_replace = "LTE_VALUE"
        }
        setKeyState(() => v4())
        setLocalState((state) => ({...state, ...sanitizeItemInputs(localitem)}))
        enforceChooser()
    }, [item])
    useEffect(() => {
        output(localState)
        setInputItemsState(() => [...recalculateInputs()])
    }, [localState])
    useEffect(() => {
        setInputItemsState(() => [...recalculateInputs()])
    }, [parentErrorState])
    useEffect(() => {
        enforceChooser()
        setInputState(() => formComponents(inputItemsState, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [inputItemsState])

    useEffect(() => {
        if (reset) {
            setLocalState(() => ({...parameters}))
            setReset(() => false)
        }
    }, [reset])

    useEffect(() => {
        setConditionalInputsState(() => formComponents(conditionalInputitemsState, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [conditionalInputitemsState])

    function enforceChooser() {
        switch (localState.replacement_type) {
            case 'INTERPOLATE':
                setConditionalInputItemsState(() => [{
                    type: 'text',
                    subType: 'number',
                    id: 'index_column',
                    invalidText: parentErrorState.parameters.index_column,
                    labelText: 'Índice de columnas',
                    placeholder: 'Introduce el índice de columnas',
                    value: localState.index_column,
                    invalid: parentErrorState.parameters.index_column,
                    parentErrorName: 'parameters'
                }])
                break;
            default:
                setConditionalInputItemsState(() => [])
                break
        }
    }


    return (
        <div>
            {inputState}
            {conditionalInputsState}
        </div>
    );
}

export default Index;
