import React, { useEffect, useRef, useState } from 'react';
import { v4 } from "uuid";
import { formComponents, parameters_kdd, sanitizeItemInputs } from "../../../../../../Utils/utils.js";

export const aritmetic_type_choose = [
    { id: 'Simple', text: 'Simple' },
    { id: 'Compleja', text: 'Compleja' },
]

export const aritmetic_operation_types = [
    { id: '+', text: '+' },
    { id: '-', text: '-' },
    { id: '*', text: '*' },
    { id: '^', text: '^' },
    { id: '/', text: '/' },
    { id: 'mod', text: 'MOD' },
    { id: 'sqrt', text: 'SQRT' },
    { id: 'log', text: 'LOG' },
]

export const aritmetic_value_choose = [
    { id: 'True', text: 'True' },
    { id: 'False', text: 'False' },
]

function Index({ item, setParentErrorState, parentErrorState, output, reset, setReset }) {
    const [keyState, setKeyState] = useState(v4())
    const inputRef = useRef()
    const [inputState, setInputState] = useState([])
    const [localState, setLocalState] = useState({ ...parameters_kdd })
    const [conditionalInputsState, setConditionalInputsState] = useState([])
    const [conditionalInputitemsState, setConditionalInputItemsState] = useState([])
    const [inputItemsState, setInputItemsState] = useState([...recalculateInputs()])
    /*const [componentInputState, setComponentInputState] = useState([])*/

    function recalculateInputs() {
        return [
            {
                type: 'text',
                id: 'co_input_column_2',
                invalidText: parentErrorState.parameters_kdd.co_input_column_2,
                labelText: 'Columna de entrada 2',
                placeholder: 'Introduce la columna de entrada 2',
                value: localState.co_input_column_2,
                invalid: parentErrorState.parameters_kdd.co_input_column_2,
                parentErrorName: 'parameters_kdd'
            },
            {
                type: 'combo',
                id: 'co_aritmetic_value',
                invalidText: parentErrorState.parameters_kdd.co_aritmetic_value,
                labelText: '¿Valor aritmético?',
                placeholder: 'Selecciona si será Valor aritmético',
                value: localState.co_aritmetic_value,
                invalid: parentErrorState.parameters_kdd.co_aritmetic_value,
                types: aritmetic_value_choose,
                parentErrorName: 'parameters_kdd'
            },
            {
                type: 'text',
                subType: 'number',
                id: 'co_value',
                invalidText: parentErrorState.parameters_kdd.co_value,
                labelText: 'Valor',
                placeholder: 'Introduce el valor',
                value: localState.co_value,
                invalid: parentErrorState.parameters_kdd.co_value,
                parentErrorName: 'parameters_kdd'
            },
            {
                type: 'combo',
                id: 'co_aritmetic_type',
                invalidText: parentErrorState.parameters_kdd.co_aritmetic_type,
                labelText: 'Tipo de operacion',
                placeholder: 'Selecciona el tipo de operacion',
                value: localState.co_aritmetic_type,
                invalid: parentErrorState.parameters_kdd.co_aritmetic_type,
                types: aritmetic_type_choose,
                parentErrorName: 'parameters_kdd'
            },
        ]
    }

    useEffect(() => {
        let localitem = { ...item }
        if (!item || Object.keys(item).length === 0) {
            localitem = { ...parameters_kdd }
        }
        setKeyState(() => v4())
        setLocalState((state) => ({ ...state, ...sanitizeItemInputs(localitem) }))
        enforceChooser()
    }, [item])
    useEffect(() => {
        output(localState)
        setInputItemsState(() => [...recalculateInputs()])
    }, [localState])
    useEffect(() => {
        setInputItemsState(() => [...recalculateInputs()])
    }, [parentErrorState])
    useEffect(() => {
        enforceChooser()
        setInputState(() => formComponents(inputItemsState, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [inputItemsState])

    useEffect(() => {
        if (reset) {
            setLocalState(() => ({ ...parameters_kdd }))
            setReset(() => false)
        }
    }, [reset])

    useEffect(() => {
        setConditionalInputsState(() => formComponents(conditionalInputitemsState, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [conditionalInputitemsState])

    function enforceChooser() {
        switch (localState.co_aritmetic_type) {
            case 'Simple':
                setConditionalInputItemsState(() => [
                    {
                        type: 'combo',
                        id: 'co_aritmetic_operation',
                        invalidText: parentErrorState.parameters_kdd.co_aritmetic_operation,
                        labelText: 'Tipo de Correlación',
                        placeholder: 'Selecciona el tipo correlación',
                        value: localState.co_aritmetic_operation,
                        invalid: parentErrorState.parameters_kdd.co_aritmetic_operation,
                        types: aritmetic_operation_types,
                        parentErrorName: 'parameters_kdd'
                    },
                ])
                break;
            case 'Compleja':
                setConditionalInputItemsState(() => [
                    {
                        type: 'text',
                        id: 'co_matriz_operation',
                        invalidText: parentErrorState.parameters_kdd.co_matriz_operation,
                        labelText: 'Matriz de operaciones aritméticas',
                        placeholder: "si quieres una ecuación compleja, puedes añadir las operaciones en orden. las operaciones son 'sum','menos','mult','div','pow','modulo','sqrt','log'",
                        value: localState.co_matriz_operation,
                        invalid: parentErrorState.parameters_kdd.co_matriz_operation,
                        parentErrorName: 'parameters_kdd'
                    },
                    {
                        type: 'text',
                        id: 'co_input_array',
                        invalidText: parentErrorState.parameters_kdd.co_input_array,
                        labelText: 'Matriz de entrada',
                        placeholder: 'En caso de que utilice la matriz de operación, es necesario definir la matriz de entrada, puede poner flotadores o nombres coulmns',
                        value: localState.co_input_array,
                        invalid: parentErrorState.parameters_kdd.co_input_array,
                        parentErrorName: 'parameters_kdd'
                    },
                ])
                break;
            default:
                setConditionalInputItemsState(() => [])
                break
        }
    }

    return (
        <div>
            {inputState}
            {conditionalInputsState}
        </div>
    );
}

export default Index;
