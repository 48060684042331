import { Add, Subtract } from "@carbon/icons-react";
import { Button, Form, Layer } from "@carbon/react";
import { useEffect, useId, useState } from "react";
import { actions, changeOrder } from "../../../../Utils/utils.js";
import AdditionalInformationToggleTip from '../../AditionalInformationToggleTip/index.js'
import SwimLane from "../SwimLane/index.js";
import JoinPhase from "./JoinPhase/index.js";

const Index = ({ item, inModal = false, output, reset, setReset, parentTargets }) => {
    let key = useId()
    key = item.id ? item.id : key
    const [localState, setLocalState] = useState([])
    const [selectedPhase, setSelectedPhase] = useState('')
    const [itemState, setItemState] = useState([...item])
    const [phaseState, setPhaseState] = useState(false)
    useEffect(() => {
        if (!phaseState) {
            setSelectedPhase(() => '')
        }
    }, [phaseState])
    useEffect(() => {
        if (!item || item === '') {
            setItemState(() => ([]))
        } else {
            setItemState(() => ([...item]))
        }
        // setLocalState(() => ([...itemState]))
    }, [item])
    useEffect(() => {
        if (itemState !== '' || itemState !== {}) {
            setLocalState(() => ([...itemState]))
        }
    }, [itemState])

    useEffect(() => {
        output({ action: 'updateJoin', data: localState })
    }, [localState])

    useEffect(() => {
        if (typeof reset === 'object') {
            setLocalState(() => [])
        }
        setPhaseState(() => false)
        setReset(() => false)
    }, [reset])

    return (
        <Layer>
            <div className="join">
                <div className={'description'}>
                    <p>Añada fases para realizar diferentes tipos de join</p>
                    <AdditionalInformationToggleTip
                        info={['INNER', 'LEFT', 'RIGHT', 'OUTER']}></AdditionalInformationToggleTip>
                    <br />
                </div>
                <div className="inner-content">
                    <Form key={key}>
                        <div className={'form-content'}>
                            {!inModal &&
                                <div className={'button-group'}>
                                    <Button type={'button'} kind={'secondary'} renderIcon={!phaseState ? Add : Subtract}
                                        iconDescription={'Añadir fase'}
                                        onClick={() => setPhaseState(() => !phaseState)}>Añadir
                                        fase</Button>
                                </div>
                            }

                        </div>
                    </Form>
                </div>
            </div>
            {phaseState &&
                <Layer>
                    <JoinPhase item={selectedPhase} output={(data, action) => joinPhaseActionChooser(data, action)}
                        inModal={false} itemCount={localState.length}
                        parentTargets={parentTargets}></JoinPhase>
                </Layer>
            }
            <div className={'swim-line-container'}>
                <Layer>
                    <SwimLane phases={localState} title={''}
                        setSelection={(phase) => {
                            setSelectedPhase(() => ({ ...phase }))
                            setPhaseState(true)
                        }}
                        changeOrder={(returnData) => changeOrder(returnData, setLocalState)}
                    />
                </Layer>
            </div>
        </Layer>
    )

    function joinPhaseActionChooser(data, action) {
        actions[action](data, localState, setLocalState, setPhaseState)
        setPhaseState(() => false)
    }
}


export default Index
