import { Save, TaskAdd, TrashCan } from "@carbon/icons-react";
import { Button, ButtonSet } from "@carbon/react";
import { useEffect, useId, useRef, useState } from "react";
import { v4 } from "uuid";
import {
    formComponents,
    isFormEmpty,
    sanitizeItemInputs,
    sanitizePhase,
    toJoinPhase,
    toSanitizePhase,
    validateForm,
    validationList
} from "../../../../../Utils/utils.js";
import SanitizeForms from "../SanitizeForms/index.js";

const sanitization_types = [
    { id: 'CLEANNING', text: 'CLEANNING' }, /* Working properly */
    { id: 'TYPEENFORCEMENT', text: 'TYPE ENFORCEMENT' }, /* Working properly */
    { id: 'REPLACENA', text: 'REPLACE NA' }, /* Working properly */
    { id: 'REPLACEVALUES', text: 'REPLACE VALUES' }, /* Working properly */
    { id: 'EXPLODE', text: 'EXPLODE' }, /* Working properly */
    { id: 'OUTLIER', text: 'OUTLIER' }, /* Working properly */
    /*{ id: 'FILTER', text: 'FILTER' },*/
]

const Index = ({ item, output, reset, setReset, itemCount, parentTargets }) => {
    let key = useId()
    key = item.id ? item.id : key
    const tempId = key
    const [keyState, setKeyState] = useState(v4())
    const [localState, setLocalState] = useState({ ...sanitizePhase })
    const [itemState, setItemState] = useState({ ...item })
    const [errorState, setErrorState] = useState({ ...sanitizePhase })
    const [sanitizeFormState, setSanitizeFormState] = useState({ ...sanitizePhase })
    const [inputState, setInputState] = useState([])
    const [targetState, setTargetState] = useState([])
    const [inputItemsState, setInputItemsState] = useState([...recalculateInputs()])
    const inputRef = useRef()

    function recalculateInputs() {
        return [
            {
                type: 'text',
                id: 'name',
                invalidText: errorState.name,
                labelText: 'Nombre de la fase',
                placeholder: 'Introduce un nombre para la fase de sanitización',
                value: localState.name,
                invalid: errorState.name,
            },
            {
                type: 'combo',
                id: 'target',
                invalidText: errorState.target,
                labelText: 'Origen',
                placeholder: 'Seleccione el origen de los datos',
                value: localState.target,
                invalid: errorState.target,
                types: targetState,
                disabled: targetState.length === 0
            },
            {
                type: 'combo',
                id: 'sanitization_type',
                invalidText: errorState.sanitization_type,
                labelText: 'Tipo de sanitización',
                placeholder: 'Seleccione el tipo de sanitización',
                value: localState.sanitization_type,
                invalid: errorState.sanitization_type,
                types: sanitization_types
            },
        ]
    }

    useEffect(() => {
        if (parentTargets) {
            // let items = parentTargets.split(',').map((e) => ({ id: e.id, text: e.text }))
            setTargetState(() => [...parentTargets])
            setErrorState((state) => ({ ...state, target: '' }))
        } else {
            setTargetState(() => [])
            // setErrorState((state) => ({ ...state, target: 'Seleccione los orígenes en el pipeline' }))
            setLocalState((state) => ({ ...state, target: '' }))
        }
    }, [parentTargets])

    useEffect(() => {
        setInputItemsState(() => [...recalculateInputs()])
    }, [targetState])

    useEffect(() => {
        if (!item || item === '') {
            setItemState(() => ({ ...sanitizePhase, sanitization_type: '' }))
        }
        setKeyState(() => v4())
        setLocalState((state) => ({ ...state, ...sanitizeItemInputs(itemState) }))
    }, [item])
    useEffect(() => {
        setInputItemsState(() => [...recalculateInputs()])
    }, [localState])
    useEffect(() => {
        setLocalState((state) => ({ ...state, ...sanitizeItemInputs(itemState) }))
    }, [itemState])
    useEffect(() => {
        setInputState(() => formComponents(inputItemsState, setLocalState, setErrorState, localState, errorState, inputRef, keyState))
    }, [inputItemsState])
    useEffect(() => {
        const filteredData = { ...sanitizeFormState }
        delete filteredData.id
        delete filteredData.name
        delete filteredData.target
        delete filteredData.sanitization_type
        filteredData.order = itemCount + 1
        //TODO: Here
        // console.log('errorState: ', errorState);
        // setErrorState(() => ({ ...sanitizeItemInputs(sanitizePhase) }))
        setLocalState((state) => ({ ...state, ...filteredData }))
    }, [sanitizeFormState])

    useEffect(() => {
        if (reset) {
            setLocalState((state) => ({ ...state, sanitization_type: '' }))
            setReset(() => false)
        }
    }, [reset])
    return (<div className="sanitizePhase">
        <div className="inner-content">
            <div className={'form-content'}>
                {inputState}
                {localState.sanitization_type !== '' &&
                    <SanitizeForms item={item ? item : ''}
                        output={(returnData) => setSanitizeFormState((prevState) => ({ ...prevState, ...returnData }))}
                        parentErrorState={errorState}
                        setParentErrorState={setErrorState}
                        type={localState.sanitization_type}
                        reset={reset} setReset={setReset}
                    />}
                {localState.sanitization_type !== '' &&
                    <ButtonSet className={'action-buttons'}>
                        <Button kind={'danger'} renderIcon={TrashCan}
                            onClick={() => deleteItem()}>Eliminar</Button>
                        <Button kind={'primary'} renderIcon={!isFormEmpty(item) ? Save : TaskAdd}
                            onClick={() => saveNewItem()}>{!isFormEmpty(item) ? 'Actualizar' : 'Añadir'}</Button>
                    </ButtonSet>}

            </div>
        </div>
    </div>)

    function saveNewItem() {
        if (validateParameters()) {
            let data = toSanitizePhase(localState)
            if (item === '') {
                if (data.id === '' && data.tempId === '') {
                    data = { ...data, tempId: tempId }
                }
                output(data, 'addPhase')
            } else {
                output(data, 'editPhase')
            }
            setLocalState(() => ({ ...sanitizeItemInputs(sanitizePhase) }))
            setItemState(() => ({ ...sanitizeItemInputs(sanitizePhase) }))
        }
        //TODO: If not valid, refresh parent components
        console.log('ErrorState: ', errorState);
    }

    function validateParameters() {
        let noValidate = Object.keys(localState.parameters).filter((e) => !validationList(localState).includes(e))
        noValidate = [...noValidate, 'id', 'global_type', 'tempId', "order", 'output_column']
        return validateForm(localState, setErrorState, noValidate)
    }

    function deleteItem() {
        if (item !== '') {
            const data = toJoinPhase(localState)
            output(data, 'deletePhase')
        }
        setLocalState(() => ({ ...sanitizeItemInputs(sanitizePhase) }))
        setErrorState(() => ({ ...sanitizeItemInputs(sanitizePhase) }))
    }

}


export default Index
