import React, {useEffect, useRef, useState} from 'react';
import {v4} from "uuid";
import {formComponents, parameters_kdd, sanitizeItemInputs} from "../../../../../../Utils/utils.js";

export const aggregation_operations = [
    { id: 'max', text: 'max' },
    { id: 'min', text: 'min' },
    { id: 'countNA', text: 'countNA' },
    { id: 'sum', text: 'sum' },
    { id: 'mean', text: 'mean' },
    { id: 'var', text: 'var' },
    { id: 'porcentile', text: 'porcentile' },
    { id: 'quartile', text: 'quartile' },
    { id: 'count', text: 'count' },
    { id: 'percent_success', text: 'percent_success' },
]

export const confidence_intervals = [
    { id: 'True', text: 'True' },
    { id: 'False', text: 'False' },
]

function Index({item, setParentErrorState, parentErrorState, output, reset, setReset}) {
    const [keyState, setKeyState] = useState(v4())
    const inputRef = useRef()
    const [inputState, setInputState] = useState([])
    const [localState, setLocalState] = useState({...parameters_kdd})
    const [conditionalInputsState, setConditionalInputsState] = useState([])
    const [conditionalInputitemsState, setConditionalInputItemsState] = useState([])
    const [inputItemsState, setInputItemsState] = useState([...recalculateInputs()])

    function recalculateInputs() {
        return [
            {
                type: 'combo',
                id: 'aggregation_operation',
                invalidText: parentErrorState.parameters_kdd.aggregation_operation,
                labelText: 'Tipo de operaciones de agregación',
                placeholder: 'Selecciona el tipo de operaciones de agregación',
                value: localState.aggregation_operation,
                invalid: parentErrorState.parameters_kdd.aggregation_operation,
                types: aggregation_operations,
                parentErrorName: 'parameters_kdd'
            },
            {
                type: 'combo',
                id: 'confidence_interval',
                invalidText: parentErrorState.parameters_kdd.confidence_interval,
                labelText: 'Intervalo de confianza',
                placeholder: 'Selecciona si hay intervalo de confianza',
                value: localState.confidence_interval,
                invalid: parentErrorState.parameters_kdd.confidence_interval,
                types: confidence_intervals,
                parentErrorName: 'parameters_kdd'
            },
        ]
    }

    useEffect(() => {
        let localitem = {...item}
        if (!item || Object.keys(item).length === 0) {
            localitem = {...parameters_kdd}
        }
        setKeyState(() => v4())
        setLocalState((state) => ({...state, ...sanitizeItemInputs(localitem)}))
        enforceChooser()
    }, [item])
    useEffect(() => {
        output(localState)
        setInputItemsState(() => [...recalculateInputs()])
    }, [localState])
    useEffect(() => {
        setInputItemsState(() => [...recalculateInputs()])
    }, [parentErrorState])
    useEffect(() => {
        setConditionalInputsState(()=>[])
        enforceChooser()
        setInputState(() => formComponents(inputItemsState, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [inputItemsState])

    useEffect(() => {
        if (reset) {
            setLocalState(() => ({...parameters_kdd}))
            setReset(() => false)
        }
    }, [reset])

    useEffect(() => {
        setConditionalInputsState(() => formComponents(conditionalInputitemsState, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [conditionalInputitemsState])

    function enforceChooser() {
        switch (localState.enforce_type) {
            case 'EXAMPLE':
                setConditionalInputItemsState(() => [{
                    type: 'text',
                    id: 'example',
                    invalidText: parentErrorState.parameters_kdd.example,
                    labelText: 'Formato de fecha',
                    placeholder: 'Introduce el formato de fecha',
                    value: localState.example,
                    invalid: parentErrorState.parameters_kdd.example,
                    parentErrorName: 'parameters_kdd'
                }])
                break;
            default:
                setConditionalInputItemsState(() => [])
                break
        }
    }

    return (
        <div>
            {inputState}
            {conditionalInputsState}
        </div>
    );
}

export default Index;
