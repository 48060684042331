import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationModel } from "../../../../Models/Notification.js";
import { sourcesActionsRequestThunk } from "../../../../store/reducers/DAToolReducer.js";
import { pushNotification } from "../../../../store/reducers/notificationsReducer.js";
import Phase from "../../DataProcess/SwimLane/Phase/index.js";

const Index = ({ phasesJoin, phasesSanitize, phasesKdd }) => {
    const dispatch = useDispatch();
    const datoolStore = useSelector((state) => state.datool)
    const [showState, setShowState] = useState(false);
    const [selectedPhase, setSelectedPhase] = useState({ id: 0, title: '' });
    const [loading, setLoading] = useState(false)
    const [joinItems, setJoinItems] = useState([])
    const [sanitizeItems, setSanitizeItems] = useState([])
    const [kddItems, setKddItems] = useState([])

    useEffect(() => {
        if (selectedPhase.id !== 0) {
            setShowState(true)
        }
    }, [selectedPhase])

    useEffect(() => {
        setLoading(() => true)
        loadDataSources()
    }, [])

    const actionSelector = {
        updateItem: (returnData) => {
            updateItem(returnData)
        }, deleteItem: (returnData) => {
            deleteItem(returnData)
        },

    }
    useEffect(() => {
        loadPhases()
    }, [phasesJoin, phasesSanitize, phasesKdd])

    const showLine = () => {
        if ((sanitizeItems.length >= 1 && joinItems.length >= 1 || kddItems.length >= 1) || (joinItems.length >= 1 && kddItems.length >= 1)) {
            return true
        } else {
            return false
        }
    }

    const loadPhases = () => {
        setSanitizeItems(() => {
            return phasesSanitize.map((p, index) => {
                return <Phase key={index} phase={p} index={index} last={phasesSanitize.length} phaseSelect={() => {
                }} showTooltip={false}></Phase>
            })
        })
        setJoinItems(() => {
            return phasesJoin.map((p, index) => {
                return <Phase key={index} phase={p} index={index} last={phasesJoin.length} phaseSelect={() => {
                }} showTooltip={false}></Phase>
            })
        })
        setKddItems(() => {
            return phasesKdd.map((p, index) => {
                return <Phase key={index} phase={p} index={index} last={phasesKdd.length} phaseSelect={() => {
                }} showTooltip={false}></Phase>
            })
        })
    }



    return (<div className="timeline-container">
        <div className={'timeline-container-inner'}>
            {showLine() &&
                <div className={'line'}></div>
            }
            {sanitizeItems.length > 0 &&
                <div className={'timeline-content'}>
                    <h3>Fases Sanitización</h3>
                    {sanitizeItems}
                </div>
            }
            {joinItems.length > 0 &&
                <div className={'timeline-content'}>
                    <h3>Fases Join</h3>
                    {joinItems}
                </div>
            }
            {kddItems.length > 0 &&
                <div className={'timeline-content'}>
                    <h3>Fases Kdd</h3>
                    {kddItems}
                </div>
            }
        </div>
    </div>)

    function actionChooser(data, action) {
        actions[action](data)
    }


    function handleSelection(phase) {
        setSelectedPhase((state) => ({ ...state, ...phase }))
    }

    /**
     * Loads sources data into store
     */
    function loadDataSources() {
        dispatch(sourcesActionsRequestThunk({ type: 'getSources', id: '' })).then((a) => {
            if (a.error) {
                dispatch(pushNotification(new NotificationModel('Error en la carga', `HTTP error: ${a.payload.http_status_code}, contacte con el administrador. soporte@espossible.com`, 'error').toJson()))
            }
            setLoading(() => false)
        }).catch(() => {
            setLoading(() => false)
        })
    }

    function updateItem(data) {
        console.log('Edit', data)
        loadDataSources()
    }

    function deleteItem(data) {
        console.log('Delete', data)
        loadDataSources()
    }

    function newItem(data) {
        console.log('New', data)
        loadDataSources()
    }

    /**
     * Function that returns sources with their id updated with id+type
     * @param sources
     * @returns {Object[]}
     */
    function uniqueIdForTable(sources) {
        let processed_sources = [...sources]
        processed_sources.forEach((e) => {
            e.id = `${e.id}@${e.global_type}`
        })
        return processed_sources
    }
}
const actions = {
    addPhase: (data) => {
        console.log(data);
    }
}
export default Index
