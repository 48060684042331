import React, {useEffect, useRef, useState} from 'react';
import {v4} from "uuid";
import {arrayToCommaString, formComponents, parameters, sanitizeItemInputs} from "../../../../../../Utils/utils.js";

export const delete_validation_options = [
    { id: 'TRUE', text: 'TRUE' },
    { id: 'FALSE', text: 'FALSE' },
]

function Index({item, setParentErrorState, parentErrorState, output, reset, setReset}) {
    const [keyState, setKeyState] = useState(v4())
    const inputRef = useRef()
    const [inputState, setInputState] = useState([])
    const [localState, setLocalState] = useState({...parameters})
    const [inputItemsState, setInputItemsState] = useState([...recalculateInputs()])

    function recalculateInputs() {
        return [
            {
                type: 'text',
                id: 'remove_outliers_groups',
                invalidText: parentErrorState.parameters.remove_outliers_groups,
                labelText: 'Outliers que desea eliminar',
                placeholder: 'Introduce los outliers que desea eliminar separados por comas',
                value: arrayToCommaString(localState.remove_outliers_groups),
                invalid: parentErrorState.parameters.remove_outliers_groups,
                parentErrorName: 'parameters'
            },
            {
                type: 'combo',
                id: 'validation_delete',
                invalidText: parentErrorState.parameters.validation_delete,
                labelText: 'Tipo de explode a realizar',
                placeholder: 'Selecciona el tipo de explode que desea realizar',
                value: localState.validation_delete,
                invalid: parentErrorState.parameters.validation_delete,
                types: delete_validation_options,
                parentErrorName: 'parameters'
            },
        ]
    }

    useEffect(() => {
        let localitem = {...item}
        if (!item || Object.keys(item).length === 0) {
            localitem = {...parameters}
        }
        setKeyState(() => v4())
        setLocalState((state) => ({...state, ...sanitizeItemInputs(localitem)}))
    }, [item])
    useEffect(() => {
        output(localState)
        setInputItemsState(() => [...recalculateInputs()])
    }, [localState])
    useEffect(() => {
        setInputItemsState(() => [...recalculateInputs()])
    }, [parentErrorState])
    useEffect(() => {
        setInputState(() => formComponents(inputItemsState, setLocalState, setParentErrorState, localState, parentErrorState, inputRef, keyState))
    }, [inputItemsState])

    useEffect(() => {
        if (reset) {
            setLocalState(() => ({...parameters}))
            setReset(() => false)
        }
    }, [reset])

    return (
        <div>
            {inputState}
        </div>
    );
}

export default Index;
